@mixin hoverGlow($area: 200px, $brightness: 0.06) {
    &:hover::before {
        opacity: 1;
    }

    &::before {
        content: "";
        background: radial-gradient($area circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, $brightness), transparent 40%);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        opacity: 0;
        transition: opacity 500ms;
    }
}

@mixin hoverGlowDark($area: 200px, $brightness: 0.06) {
    &:hover::before {
        opacity: 1;
    }

    &::before {
        content: "";
        background: radial-gradient($area circle at var(--mouse-x) var(--mouse-y), rgba(0, 0, 0, $brightness), transparent 40%);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        opacity: 0;
        transition: opacity 500ms;
    }
}