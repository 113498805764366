#leftNavigation {
    position: fixed;
    top: 96px + 64px;
    left: inherit;
    width: 322px;
    padding: 64px 72px 0px 72px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: ($screenWidthSmallFont+1)) and (max-width: 1024px) {
        left: -60px;
        padding-left: 96px;
        width: auto;
        padding-right: 48px;
    }

    @media (max-width: $screenWidthSmallFont) {
        display: none;
    }
}

#ministriesMenu {
    position: relative;
    line-height: 17px;
    letter-spacing: 0.025em;
    z-index: 2;

    ul.menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 40px;
        list-style-type: none;
        margin: 0;

        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 8px;
            gap: 8px;
        
            border-left: 1px solid $neutralMainColor5;

            a:hover {
                font-weight: 700;
                color: inherit;
            }

            a.selected {
                font-weight: 700;
            }
        }
    }
}

body.dark #ministriesMenu {
    ul.menu {
        li {
            border-left: 1px solid $neutralMainColor1;
        }
    }
}