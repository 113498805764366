#topMenuCategories {
    position: sticky;
    width: 100%;
    padding: 16px 40px 0 40px;

    top: 96px;
    z-index: 3;

    @media (max-width: $screenWidthSmallFont) {
        top: 64px;
    }

    .topMenuCategoriesContainer {
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: row;
        gap: 7px;
        justify-content: center;

        .category {
            @include fontSubheading();
            flex: 1;
            align-items: center;
            border-bottom: 1px solid $neutralMainColor2;
            cursor: pointer;
            text-transform: uppercase;
            
            &.selected {
                font-weight: 600;
                border-bottom: 3px solid $neutralMainColor5;
                background-color: $primaryColor;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }
}

body{
    #topMenuCategories {
        background-image: url("./../img/background-csmall.jpg");
    }

    &.dark #topMenuCategories {
        background-image: none;
        background-color: $neutralMainColor5;
    }
}

body.dark #ministryCategories {
    .ministryCategoriesContainer {
        .ministry {
            border-bottom: 1px solid $neutralMainColor1;
            
            &.selected {
                border-bottom: 3px solid $neutralMainColor1;
            }
        }
    }
}

body.dark #topMenuCategories {
    .topMenuCategoriesContainer {
        .category.selected {
            border-bottom: 3px solid $neutralMainColor2;
        }
    }
}