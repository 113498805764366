@import "./../fonts/baskerville/style.css";
@import "./../fonts/fragilescript/style.css";
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@mixin fontSizeNav() {
    font-size: 14px;
    line-height: 17.07px;

    @media screen and (max-width: $screenWidthSmallFont) {
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
    }
}

@mixin fontSizeBodyMobile() {
    font-size: 10px;
    line-height: 19px;
    letter-spacing: 0.02em;
}

@mixin fontSizeBody() {
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.04em;
    font-weight: 500;

    @media (max-width: $screenWidthSmallFont) {
        @include fontSizeBodyMobile();
    }
}

@mixin fontTagline() {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.04em;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 10px;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }
}

@mixin fontSubheading() {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.025em;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
    }
}

@mixin fontTitle() {
    font-size: 46px;
    line-height: 56px;
    font-weight: 400;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 23px;
        line-height: 28px;
    }
}

@mixin fontScriptType($fontSize: 42px, $lineHeight: 76px, $letterSpacing: -0.045em) {
    font-family: "Fragile Script";
    font-size: $fontSize;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 20px;
        line-height: 36px;
        letter-spacing: -0.045em;
    }
}

@mixin fontStylizedButton() {
    font-weight: 700;
    font-size: 14px;
    line-height: 10px;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 9px;
        line-height: 10px;
    }
}

@mixin fontSubheading2() {
    font-family: "Baskerville Normal", serif;
    font-weight: 400;
    font-size: 54px;
    line-height: 61px;
    font-style: normal;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 24px;
        line-height: 27px;
    }
}

@mixin fontLibreItalic() {
    font-family: "Libre Baskerville", serif;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 9px;
        line-height: 13.5px;
        letter-spacing: 0.02em;
    }
}

@mixin fontLibreNormal() {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 14px;
        line-height: 17px;
    }
}

@mixin fontLibreHeading() {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    text-transform: uppercase;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 20px;
        line-height: 25px;
    }
}

@mixin fontDateDay {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 16px;
        line-height: 20px;
    }
}

@mixin fontSpeaker {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 9px;
        line-height: 190%;
        letter-spacing: 0.18px;
    }
}