#familyCell {
    .imageContainer {
        border: 1px solid $neutralMainColor2;
        width: 100%;
        border-radius: 120px 0px;
        background-color: $neutralMainColor3;
        aspect-ratio: 1080/608;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @media (max-width: 1024px) {
            border-radius: 80px 0px;
        }

        @media (max-width: $screenWidthSmallFont) {
            border-radius: 40px 0px;
        }
    }

    .quote {
        @include fontLibreItalic();
        margin-bottom: 80px;

        @media (max-width: $screenWidthSmallFont) {
            margin-bottom: 48px;
        }
    }

    .contentContainer {
        padding: 64px 41px;
        border-left: 1px solid $neutralMainColor2;

        @media (max-width: $screenWidthSmallFont) {
            padding: var(--default-padding-left-mobile);
        }
    
        .article {
            justify-content: space-between;
            gap: 40px;

            @media (max-width: $screenWidthSmallFont) {
                gap: 8px;
            }

            .flexColumn {
                flex: 1;
                min-width: 250px;
            }
        }
    }

    .callAction {
        border: 1px solid $neutralMainColor2;
        align-items: flex-end;
        padding: 40px 180px;
        background-color: $primaryColor;
        color: $neutralMainColor5;
        border-radius: 0 0 120px 120px;

        @media (max-width: 1280px) {
            padding: 40px 80px;
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            border-radius: 0 0 80px 80px;
            padding: 40px 60px;
        }

        @media (max-width: $screenWidthSmallFont) {
            padding: var(--default-padding-left-mobile);
            border-radius: 0 0 40px 40px;
        }

        .callActionContainer {
            flex: 1;
        }

        .tagline {
            @include fontTagline();
            margin-bottom: 16px;
        }
        
        button {
            background-color: $neutralMainColor5;
            color: $neutralMainColor1;
        }
    }
}