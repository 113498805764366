#weAreFamily {
    @include sectionPadding();
    height: 940px;

    background-image: url('./../../../img/page/home/weAreFamily/shadow.png'), url('./../../../img/page/home/weAreFamily/mainPattern-csmall.png');
    background-repeat: no-repeat;
    background-position: top left, bottom right;

    @include hoverGlow(800px, 0.4);

    h1 {
        @include title($neutralMainColor5, left, $neutralMainColor4, 12px, 156px, 0, auto);

        img {
            left: 222px;
            margin-top: 10px;

            @media (max-width: 1200px) {
                left: 114px;
                margin-top: 4px;
                width: 14px !important;
            }
        }
    }

    @media (max-width: 1200px) {
        height: auto;

        // background-image: url('./../../../img/page/home/weAreFamily/shadow.png'), url('./../../../img/page/home/weAreFamily/mainPattern-mobile.png');
        background-image: none;
        background-repeat: no-repeat;
        background-position: top left, 0 76px;
        background-size: auto, 100%;
    }

    #leadersCards {
        position: relative;
        overflow: hidden;
    }

    .leaderCard {
        position: absolute;
        display: none;

        .imageContainer, .cardContent .name {
            animation: fadeOut 0.5s ease-in-out forwards;
        }
        
        &.show {
            display: block;

            .imageContainer, .cardContent .name {
                animation: fadeIn 0.5s ease-in-out forwards;
            }
        }
    }

    .card {
        position: relative;
        width: 100%;
        height: 690px;
        background: $neutralMainColor1;
        border: none;

        mask-image: url('./../../../img/card/component3Mask.svg');
        border-bottom-right-radius: 50px;
        
        @media (max-width: 1200px) {
            mask-image: none;
            height: auto;
        }

        .cardShadow {
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
            border-radius: 0px 0px 40px 40px;
            pointer-events: none;
        }

        .cardContent {
            display: flex;
            height: 100%;

            .imageContainer {
                background: $neutralMainColor3;
                width: 530px;
                height: 100%;
                overflow: hidden;

                background-repeat: no-repeat;
                background-position: top center;
                background-size: cover;

                aspect-ratio: 1/1;

                // img {
                //   height: 100%;
                //   width: auto;
                // }
            }

            .textContainer {
                max-width: 525px;
                width: 100%;
                padding: 0 60px 0 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name {
                    @include fontScriptType();
                }

                .description {
                    margin-left: 18px;

                    .title {
                        @include fontSubheading();
                        font-weight: 400;
                        margin-bottom: 40px;

                        @media (max-width: 1200px) {
                            margin-bottom: 20px;
                        }
                    }

                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            border-radius: 0px 0px 20px 20px;

            .cardShadow {
                box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
                border-radius: 0px 0px 20px 20px;
            }

            .cardContent {
                display: block;
                padding-bottom: 16px;

                .imageContainer {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1/1;
                }

                .textContainer {
                    display: block;
                    overflow-y: hidden;

                    &:hover {
                        overflow-y: auto;
                    }

                    margin: 16px 25px 16px 50px;
                    padding: 0;
                    padding-right: 25px;
                    height: 300px;
                    width: fit-content;
                    scrollbar-gutter: stable;
                }
            }
        }
    }

    .centerContainer {
        position: absolute;
        left: 245px;
        bottom: 32px;

        @media (max-width: 1200px) {
            display: flex;
            margin: auto;
            width: 100%;
            
            justify-content: center;
            bottom: 24px;
            left: 0;
        }

        @media (max-width: $screenWidthSmallFont) {
            bottom: 0;
        }
    }

    #weAreFamilyPageNav {
        position: relative;
    }
}