#getToKnowUs {
    .ourValues {
        &>.background {
            width: var(--default-padding-left);
            height: 1704px;
            top: 315px;
            border-radius: 240px 0px 0px 240px;

            @media (min-width: ($screenWidthSmallFont+1)) and (max-width: 1024px) {
                width: calc(var(--default-padding-left) - $mediumPaddingDiff);
            }

            @media (max-width: $screenWidthSmallFont) {
                display: none;
            }
        }

        h1 {
            margin-left: -240px;
            color: $neutralMainColor5;

            .iconSvg {
                .iconColor {
                    fill: $neutralMainColor5;
                }
            }
        }

        .lineL {
            position: absolute;
            top: 56px + 80px - 13px;
            left: var(--default-padding-left);

            .line {
                stroke: $neutralMainColor3;
                stroke-width: 0.5px;
            }

            &.lpad {
                left: calc(var(--default-padding-left) + 51px);
            }

            @media (max-width: 1024px) {
                left: calc(var(--default-padding-left) - $mediumPaddingDiff);

                &.lpad {
                    left: calc(var(--default-padding-left) - $mediumPaddingDiff + 51px);
                }
            }

            @media (max-width: $screenWidthSmallFont) {
                left: var(--default-padding-left-mobile);

                &.lpad {
                    display: none;
                }
            }
        }

        .point {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-top: -13px;
            margin-left: 11px;
            z-index: 1;
            gap: 4px;

            .icon {
                width: 80px;
                height: 80px;

                @media (max-width: $screenWidthSmallFont) {
                    width: 40px;
                    height: 40px;
                }
            }

            .text {
                @include fontScriptType();
            }
        }

        .subheading {
            position: relative;
            top: -1px;
            width: 100%;
            background-color: $neutralMainColor1;
            z-index: 1;
            border: 1px solid $neutralMainColor2;
            filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25));

            .text {
                color: $neutralMainColor5;
            }

            .description {
                @include fontLibreItalic();
                color: black;

                max-width: 65%;
            }
        }

        .visionContainer {
            .subheading {
                border-bottom-right-radius: 78%;

                .description {
                    padding-left: $stickyLeftPaddingMobile;
                    margin-bottom: 11px;
                }
            }
        }

        .missionContainer {
            margin-top: 36px;

            .subheading {
                border-bottom-right-radius: 64%;

                .text {
                    margin-bottom: 0;
                    max-width: 80%;
                }
            }
        }

        .valuesContainer {
            overflow: hidden;
            margin-top: 23px;

            .subheading {
                border-bottom-right-radius: 100%;
                max-width: 730px;
            }
        }

        @media (min-width: ($screenWidthSmallFont + 1)) {

            .visionContainer,
            .missionContainer {
                .subheading {

                    .text,
                    .description {
                        padding-left: 0;
                        padding-top: 0;
                    }
                }
            }

            .visionContainer {
                .subheading {
                    border-bottom-right-radius: 78%;
                    padding: 32px 92px 40px 92px;

                    .description {
                        margin-bottom: 0;
                    }
                }
            }

            .missionContainer {
                margin-top: 36px;

                .subheading {
                    border-bottom-right-radius: 64%;
                    padding: 16px 92px 18px 92px;

                    .text {
                        margin-bottom: 0;
                        max-width: 80%;
                    }
                }
            }

            .valuesContainer {
                margin-top: 23px;

                .subheading {
                    border-bottom-right-radius: 100%;
                    padding: 18px 92px 24px 92px;
                    max-width: 730px;
                }
            }
        }
    }
}

.missionContainer,
.valuesContainer {
    padding-left: 51px;
    padding-right: 68px;
    margin-bottom: 80px;
    position: relative;

    @media (max-width: $screenWidthSmallFont) {
        padding-left: 0;
        padding-right: var(--default-padding-left-mobile);
    }
}

.visionContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    position: relative;
}

.visionCards {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -200px;
    padding-left: 51px;
    padding-right: 68px;
    gap: 9px;
    flex-wrap: wrap;

    .vRow {
        display: flex;
        flex-direction: row;
        gap: 9px;
    }

    .vCard {
        position: relative;
        width: 209px;
        background: $neutralMainColor5;
        border-radius: 0px 0px 60px 0px;

        .textContainer {
            padding-top: 400px;
            padding-bottom: 21px;

            .title {
                @include fontLibreNormal();

                text-align: center;
                text-transform: uppercase;

                color: white;
                margin-bottom: 20px;
            }

            .description {
                color: $neutralMainColor1;
                margin-inline: auto;
                max-width: 84%;
            }
        }

        .imgContainer {
            position: absolute;
        }

        &.spiritual {
            .imgContainer {
                top: 221px;
                left: 33px;
            }
        }

        &.physical {
            .imgContainer {
                top: 192px;
                left: 85px;
            }
        }

        &.relationship {
            .imgContainer {
                top: 268px;
                left: 65px;
            }
        }

        &.finance {
            .imgContainer {
                top: 112px;
                left: 29px;
            }
        }

        &.character {
            .imgContainer {
                top: 187px;
                left: 64px;
            }
        }
    }

    @media (max-width: 1024px) {
        margin-top: -207px;
        flex-direction: column;
        padding-left: 0;
        padding-right: var(--default-padding-left-mobile);

        .vRow {
            flex-direction: column;

            .vCard {
                width: 100%;

                display: flex;
                flex-direction: row;
                min-height: 160px;
                padding-bottom: 15px;

                .imgContainer {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: flex;
                    width: 100px;
                    justify-content: center;
                }

                .textContainer {
                    padding: 20px;
                    flex: 1;

                    .title {
                        text-align: left;
                        margin-bottom: 5px;
                    }

                    .description {
                        max-width: 100%;
                    }
                }

                &.spiritual {
                    padding-top: 210px;

                    img {
                        width: 87px;
                        height: auto;
                    }
                }

                &.physical {
                    img {
                        width: 30px;
                        height: auto;
                    }
                }

                &.relationship {
                    img {
                        width: 64px;
                        height: auto;
                    }
                }

                &.finance {
                    img {
                        width: 74px;
                        height: auto;
                    }
                }

                &.character {
                    img {
                        width: 59px;
                        height: auto;
                    }
                }

                &.physical,
                &.finance {
                    .imgContainer {
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        margin-top: -80px;

        .vRow {
            .vCard.spiritual {
                padding-top: 80px;
            }
        }
    }
}

.missionCard {
    position: relative;
    width: 100%;
    border-radius: 0px 0px 80px 0px;
    overflow: hidden;
    margin-top: -96px;

    @media (max-width: $screenWidthSmallFont) {
        margin-top: -64px;
    }

    .background {
        position: absolute;
        top: 0;
        right: 0;
        background-repeat: no-repeat;
        mask-image: url('./../../../img/page/getToKnowUs/ourValues/maskMission.svg');
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat !important;
        mask-position: top left 20px;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .cardContent {
        position: relative;
        padding-top: 165px;
        margin-left: 44px;

        .description1 {
            width: 100%;
            height: 192px;
            max-width: 384px;
            margin-bottom: 89px;
            @include fontLibreItalic();
        }

        .description2 {
            width: 100%;
            max-width: 706px;
            margin-bottom: 42px;
        }

        button {
            color: $neutralMainColor1;
            background-color: $neutralMainColor5;
        }
    }
}

.description1 {
    shape-margin: 10px;

    .shape {
        // background-color: red;
        // clip-path: polygon(0 0, 88px 200px, 88px 0);
        shape-outside: polygon(0 0, 88px 200px, 88px 0);
        width: 150px;
        height: 200px;
        float: right;
        margin: 0;
        shape-margin: 20px;
    }
}

.pointWrapper {
    padding-top: 53px;
    background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.3125) 44.79%, rgba(217, 217, 217, 0) 100%);
}

.fiestaCards {
    position: relative;
    padding-top: 40px;

    .fCard {
        position: relative;
        height: 250px;
        margin-bottom: 40px;

        .background {
            position: absolute;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
            padding-left: 36px;

            .center {
                flex: 1;
            }
        }

        .icon {
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .cardContent {
            display: flex;
            position: relative;
            padding-block: 34px;
            gap: 20px;

            .title {
                width: 310px;
                text-align: right;
                @include fontLibreHeading();
                color: black;
            }

            .description {
                flex: 1;

                p {
                    width: 100%;
                    max-width: 276px;
                }
            }

            @media (max-width: 1024px) {
                flex-direction: column;
                justify-content: flex-start;
                padding-left: 174px;

                .title {
                    text-align: left;
                }

                .description {
                    p {
                        width: 80%;
                        max-width: 100%;
                    }
                }
            }
        }

        &.f {
            .left {
                width: 95px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-F-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-F-center.png');
            }

            .right {
                width: 85px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-F-right.png') no-repeat;
            }

            .icon {
                width: 147px;
                height: 249px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/fIcon.svg') no-repeat;
            }
        }

        &.i {
            .left {
                width: 67px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-I-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-I-center.png');
            }

            .right {
                width: 90px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-I-right.png') no-repeat;
            }

            .icon {
                width: 107px;
                height: 258px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/iIcon.svg') no-repeat;
                bottom: -15px;
            }
        }

        &.e {
            .left {
                width: 70px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-E-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-E-center.png');
            }

            .right {
                width: 62px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-E-right.png') no-repeat;
            }

            .icon {
                width: 164px;
                height: 165px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/eIcon.svg') no-repeat;
            }
        }

        &.s {
            .left {
                width: 60px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-S-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-S-center.png');
            }

            .right {
                width: 117px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-S-right.png') no-repeat;
            }

            .icon {
                width: 123px;
                height: 181px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/sIcon.svg') no-repeat;
                bottom: -15px;
            }
        }

        &.t {
            .left {
                width: 74px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-T-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-T-center.png');
            }

            .right {
                width: 97px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-T-right.png') no-repeat;
            }

            .icon {
                width: 119px;
                height: 195px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/tIcon.svg') no-repeat;
            }
        }

        &.a {
            .left {
                width: 85px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-A-left.png') no-repeat;
            }

            .center {
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-A-center.png');
            }

            .right {
                width: 65px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/baseFiesta-A-right.png') no-repeat;
            }

            .icon {
                width: 155px;
                height: 166px;
                background: url('./../../../img/page/getToKnowUs/ourValues/fiesta/aIcon.svg') no-repeat;
            }
        }
    }
}