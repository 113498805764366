#give {
    margin-top: 95px;
    position: relative;

    @media (max-width: $screenWidthSmallFont) {
        margin-top: 64px;
    }
}

.giveContainer {
    padding-block: 88px;

    @media (max-width: 1280px) {
        padding-inline: 64px;
    }

    @media (max-width: $screenWidthSmallFont) {
        padding-block: 44px;
        padding-inline: var(--default-padding-left-mobile);
    }
}

#giveCards {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 88px;
    max-width: 939px;
    margin: auto;

    @media (max-width: $screenWidthSmallFont) {
        gap: 44px;
    }

    .giveCard {
        border-radius: 40px;
        background-color: $neutralMainColor3;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.50);
        width: 100%;
        aspect-ratio: 939/528;
        position: relative;
        background-size: cover;
        background-position: center center;

        @media (max-width: 1024px) {
            aspect-ratio: 1;
        }

        @media (max-width: 550px) {
            aspect-ratio: 528/939;
        }

        .headerContainer {
            width: fit-content;
            position: absolute;
            top: -36px;
            left: 0;

            @media (max-width: $screenWidthSmallFont) {
                top: 0;
            }
        }

        .bodyContainer {
            width: 100%;
            position: absolute;
            bottom: 40px;
            left: 0;
            color: $neutralMainColor1;

            @media (max-width: $screenWidthSmallFont) {
                bottom: var(--default-padding-left-mobile);
                max-width: 326px + 48px;
            }

            .body {
                display: flex;
                flex-direction: row;

                @media (max-width: 1024px) {
                    flex-direction: column;
                    padding-inline: 50px;
                }

                @media (max-width: $screenWidthSmallFont) {
                    padding-inline: var(--default-padding-left-mobile);
                }

                .text {
                    padding-left: 50px;
                    width: 100%;

                    @media (max-width: 1024px) {
                        padding-block: var(--default-padding-left-mobile);
                        padding-inline: 0;
                    }

                    .title, .description {
                        max-width: 420px;
                    }

                    .title {
                        @include fontTagline();
                        letter-spacing: 0.8px;
                        margin-bottom: 16px;
                    }

                    .description {
                        letter-spacing: 0.56px;
                    }
                    
                }

                .info {
                    flex-shrink: 0;
                    width: 326px;
                    padding: 32px var(--default-padding-left-mobile);
                    border-left: 2px solid $neutralMainColor1;

                    @media (max-width: 1024px) {
                        padding-block: var(--default-padding-left-mobile);
                        padding-inline: 0;
                        border-left: none;
                        border-top: 2px solid $neutralMainColor1;
                    }

                    @media (max-width: $screenWidthSmallFont) {
                        max-width: 326px;
                        width: 100%;
                    }
                
                    .title {
                        @include fontTagline();
                        letter-spacing: 0.8px;
                        margin-bottom: 16px;
                    }

                    .account {
                        letter-spacing: 0.56px;
                    }

                    .accountNumber {
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.8px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}