.contactUs2 {
    position: relative;
    height: fit-content;
    padding-block: 102px;
    text-align: center;

    .subsection {
        display: flex;
        gap: 38px;
        position: absolute;
        right: 90px;
        top: 22px;

        span {
            @include fontSubheading();
            font-weight: 400;
            color: white;
        }

        .socmedIcon {
            flex-direction: row;
            height: auto;
            gap: 46px;
            position: relative;
            top: auto;
            left: auto;
            width: fit-content;

            button {
                height: 30px;
                padding: 0;

                img {
                    height: 30px;
                    width: auto;
                }
            }

            @media (max-width: $screenWidthSmallFont) {
                gap: 18px;

                button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 12px;

                    img {
                        height: 12px;
                    }
                }
            }
        }

        @media (max-width: 869px) {
            width: 100%;
            right: 0;
            justify-content: flex-end;
            padding-right: 36px;
        }

        @media (max-width: $screenWidthSmallFont) {
            gap: 12px;
            top: 6px;
        }
    }

    h3 {
        font-weight: 600;
        letter-spacing: -0.01em;
        color: black;
        align-self: flex-start;
        margin-bottom: 0;

        @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 869px) {
            font-size: 25px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px;
        gap: 14px;

        max-width: 640px;
        width: 100%;
        margin: auto;

        input {
            height: 36px;
        }

        textarea {
            height: 160px;
            padding-block: 10px;
        }

        input,
        textarea {
            width: 100%;
            border-radius: 4px;
            background-color: $neutralMainColor5;
            padding-left: 22px;

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.025em;
            color: $neutralMainColor1;

            &:hover {
                background-color: $neutralMainColor3;
                ;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $neutralMainColor1;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $neutralMainColor1;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $neutralMainColor1;
            }
        }

        button {
            font-weight: 700;
            font-size: 20px;
            line-height: 10px;
            height: 38px;
        }
    }

    @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 869px) {
        form {
            max-width: 76%;
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        height: 457px;
        padding-top: 56px;
        background-image: none;

        form {
            padding: 0 60px;

            input,
            textarea {
                font-size: 8px;
                line-height: 10px;
                letter-spacing: 0.02em;
            }

            button {
                font-size: 9px;
                line-height: 11px;
                height: 24px;
            }
        }
    }
}

.subscribe {
    position: relative;
    height: fit-content;
    padding-block: 102px;
    text-align: center;

    .subsection {
        display: flex;
        gap: 38px;
        position: absolute;
        right: 90px;
        top: 22px;

        span {
            @include fontSubheading();
            font-weight: 400;
            color: white;
        }

        .socmedIcon {
            flex-direction: row;
            height: auto;
            gap: 46px;
            position: relative;
            top: auto;
            left: auto;
            width: fit-content;

            button {
                height: 30px;
                padding: 0;

                img {
                    height: 30px;
                    width: auto;
                }
            }

            @media (max-width: $screenWidthSmallFont) {
                gap: 18px;

                button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 12px;

                    img {
                        height: 12px;
                    }
                }
            }
        }

        @media (max-width: 869px) {
            width: 100%;
            right: 0;
            justify-content: flex-end;
            padding-right: 36px;
        }

        @media (max-width: $screenWidthSmallFont) {
            gap: 12px;
            top: 6px;
        }
    }

    h3 {
        font-weight: 600;
        letter-spacing: -0.01em;
        color: black;
        align-self: flex-start;
        margin-bottom: 0;

        @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 869px) {
            font-size: 25px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px;
        gap: 14px;

        max-width: 640px;
        width: 100%;
        margin: auto;

        input {
            height: 36px;
        }

        textarea {
            height: 160px;
            padding-block: 10px;
        }

        input,
        textarea {
            width: 100%;
            border-radius: 4px;
            background-color: $neutralMainColor5;
            padding-left: 22px;

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.025em;
            color: $neutralMainColor1;

            &:hover {
                background-color: $neutralMainColor3;
                ;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $neutralMainColor1;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $neutralMainColor1;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $neutralMainColor1;
            }
        }

        button {
            font-weight: 700;
            font-size: 20px;
            line-height: 10px;
            height: 38px;
        }
    }

    @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 869px) {
        form {
            max-width: 76%;
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        height: 457px;
        padding-top: 56px;
        background-image: none;

        form {
            padding: 0 60px;

            input,
            textarea {
                font-size: 8px;
                line-height: 10px;
                letter-spacing: 0.02em;
            }

            button {
                font-size: 9px;
                line-height: 11px;
                height: 24px;
            }
        }
    }
}
.frameBackground {
    --size: 70px;
    --border-radius: 100%;

    --space-height: 75px;
    --space-width: 90px;

    &.thin {
        --size: 50px;
    }

    @media (max-width: $screenWidthSmallFont) {
        --size: 25px;
        --border-radius: 50%;

        --space-height: 45px;
        --space-width: 45px;

        &.thin {
            --size: 25px;
        }
    }

    @include hoverGlow(600px, 0.2);

    position: absolute;
    display: flex;
    gap: 0;

    &.colored,
    .colored {
        background-color: $neutralMainColor5;
    }

    &.inline {
        top: 0;
        width: var(--size);
        height: 100%;
        flex-direction: column;
    }

    &.left {
        left: 0;

        .roundedBackground {
            height: var(--size);
            border-top-right-radius: var(--border-radius);
        }

        .square {
            flex: 1;
        }
    }

    &.top {
        top: 0;
        right: 0;
        justify-content: flex-end;

        .roundedBackground {
            width: var(--size);
            border-bottom-left-radius: var(--border-radius);
        }

        .square {
            width: 770px;

            @media (max-width: $screenWidthSmallFont) {
                width: 275px;
            }
        }
    }

    &.top2 {
        top: 0;
        left: 0;
        justify-content: flex-start;

        .roundedBackground {
            width: var(--size);
            border-bottom-right-radius: var(--border-radius);
        }

        .square {
            width: Calc(100% - var(--space-width) - var(--size));
        }
    }

    &.block {
        height: var(--size);
        width: 100%;
        flex-direction: row;
    }

    &.right {
        right: 0;

        .spacer {
            height: var(--space-height);
        }

        .roundedBackground {
            height: var(--size);
            border-top-left-radius: var(--border-radius);
        }

        .square {
            flex: 1;
        }
    }

    &.bottom {
        bottom: 0;
    }
}

#footer {
    height: 752px;
    background-image: url("./../../../img/page/home/signIn/mainPattern-csmall.png");
    background-position: top center;

    @include hoverGlow(2000px, 0.3);

    @media (max-width: $screenWidthSmallFont) {
        height: 222px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.buttonClose {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

    @media (max-width: $screenWidthSmallFont) {
        top: 0;
        right: 0;
        transform: scale(0.75);
    }

    &.type2 {
        top: 0;
        right: 0;
    }
}