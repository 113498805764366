@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
        width: 100%;
        height: inherit;
        margin: inherit;
        overflow: inherit;
    }
    100% {
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        overflow: hidden;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
