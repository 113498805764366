.top-nav-menu {
    background-color: $neutralMainColor5;
    padding: 0;
    align-content: flex-end;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    height: 96px;
    width: 100%;
    max-width: $maxWidth;
    z-index: 999;

    @include hoverGlow();

    a.navbar-brand {
        cursor: pointer;
        z-index: 1;

        transition: filter 0.3s;

        &:hover {
            filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));
        }
    }

    img#logo {
        width: 180px;
        height: auto;
    }

    div.container-fluid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 40px 72.5px 8px;
    }

    .collapsing {
        // height transition
        transition: height 0.35s ease;
    }

    .navbar-collapse {
        flex-grow: inherit;

       &.collapsing, &.show {
            position: fixed;
            top: 95px;
            left: -1px;

            @media (max-width: $screenWidthSmallFont) {
                top: 63px;
            }

            .navbar-nav {
                flex-direction: column;
                gap: 0;

                li a {
                    padding: 7px 25px;
                    background: $neutralMainColor4;

                    border: 0.5px solid $neutralMainColor2;
                    border-radius: 0px 20px 20px 0px;

                    display: block;

                    @media (min-width: ($screenWidthSmallFont + 1)) {
                        padding: 17px 75px;
                        border-radius: 0px 30px 30px 0px;
                    }

                    &:hover, &:active {
                        background: $primaryColor;
                        color: $neutralMainColor5;
                        padding-right: 40px;
                        font-weight: 700;

                        @media (min-width: ($screenWidthSmallFont + 1)) {
                            padding-right: 120px;
                        }
                    }
                }
            }
        }
    }

    .nav,
    .navbar-nav {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 64px;
        pointer-events: none;
        z-index: 1;

        li.nav-item {
            flex: none;
            order: 0;
            flex-grow: 0;

            &:last-child {
                margin-right: 0;
            }

            a {
                @include fontSizeNav();
                color: $neutralMainColor1;
                padding: 0;
                text-decoration: none;
                pointer-events: auto;
                transition: filter 0.3s;

                &:hover {
                    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));
                }
            }

            .dropdown-menu.show {
                button {
                    pointer-events: all;
                }
            }
        }

        &.account {
            gap: 13px;
            align-items: center;

            .langSelection {
                margin-top: 8px;
                margin-left: 5px;
            }
        }
    }

    .show .navbar-nav li a, .navbar-nav li.nav-item a{
        &.active {
            font-weight: 700;
        }
    }

    .navbar-toggler {
        @include fontSizeNav();
        color: $neutralMainColor1;
        align-items: center;
        font-weight: 400;
        z-index: 1;
        border: 0;

        &:focus,
        &:focus-visible {
            outline: none;
            box-shadow: none;
        }

        img {
            margin-right: 4px;
        }
    }
}

@media (max-width: 1199px) {
    .top-nav-menu {
        .navbar-toggler {
            display: flex;
            order: 1;
            padding: 0;

            img {
               transition: transform 0.3s ease;
            }

            &.show {
                img {
                    transform: rotate(45deg);
                }
            }
        }

        a.navbar-brand {
            order: 2;
            display: flex;
        }

        ul.account {
            order: 3;
        }
    }
}

@media (max-width: $screenWidthSmallFont) {
    .top-nav-menu {
        height: auto;

        div.container-fluid {
            width: 100%;
            gap: inherit;
            padding: 18px 22px;
        }

        .navbar-toggler svg {
            width: 11px;
        }

        img#logo {
            width: 112px;
        }

        .langFlag {
            width: 9px;
            height: auto;
        }

        .langSelection {
            width: 6px;
            height: auto;
        }
    }
}

body.dark .top-nav-menu{
    @include hoverGlowDark();

    a.navbar-brand {
        cursor: pointer;
        z-index: 1;

        transition: filter 0.3s;

        &:hover {
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
        }
    }

    .nav,
    .navbar-nav {
        li.nav-item {
            a {
                &:hover {
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
                }
            }
        }
    }
}