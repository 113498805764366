button {
    background: none;
    border: none;
}

@keyframes rotating {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

.normalButton {
    @include fontStylizedButton();
    background: $primaryColor;
    border: none;
    text-align: center;
    padding: 10px 14px;
    border-radius: 20px;

    @media (max-width: $screenWidthSmallFont) {
        padding: 5px 10px;
    }

    &:hover {
        filter: brightness(0.8);
    }

    &:active {
        filter: contrast(0.7);
    }
}

.specialButton {
    position: absolute;
    width: 79px;
    height: 212px;
    right: 380px;
    bottom: 59px;
    z-index: 2;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .line {
        height: 100%;
        width: 0;
        border-left: 1px solid $neutralMainColor2;
        margin-top: -20px;
        z-index: 0;
    }

    img, button {
        z-index: 1;
    }

    .specialButtonContainer {
        @include lineComponentAbsolutePosition(-13px, auto, auto, auto, $neutralMainColor5, 0.25px);
        position: relative;
        display: block;
        margin: auto;
    }

    &:hover {
        img {
            animation: rotating 3s ease-in-out infinite;
        }
    }

    .normalButton {
        position: relative;
        top: -13px;
        color: $neutralMainColor1;
        background-color: $neutralMainColor5;

        &:active {
            filter: contrast(1);
            background-color: $primaryColor;
        }
    }

    @media (max-width: 1310px) {
        right: auto;
        left: 692px;
    }

    @media (max-width: 976px) {
        right: 55px;
        left: auto;
    }

    @media (max-width: $screenWidthSmallFont) {
        right: 0px;
        height: 142px;
        bottom: 6px;

        img {
            width: 24px;
        }
    }

    .isLive {
        background-color: $okColor;
        border: none;
    }
}

.uniqueButton {
    @include fontStylizedButton();
    display: flex;
    border: none;
    background: none;
    color: $neutralMainColor1;

    &:hover {
        opacity: 0.6;
    }

    .part {
        height: 31px;

        &:nth-child(1) {
            width: 40px;
            background-image: url("./../img/uniqueButton/left.png");
        }

        &:nth-child(2) {
            width: auto;
            background-image: url("./../img/uniqueButton/center-1px.png");
            display: flex;
            align-items: center;
            text-align: center;
            padding: 10px;
        }

        &:nth-child(3) {
            width: 11px;
            background-image: url("./../img/uniqueButton/right.png");
        }
    }
}
