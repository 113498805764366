#contactUs {
    position: absolute;
    max-width: 1300px;
    width: 100%;
    height: 72px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;

    @media (max-width: $screenWidthSmallFont) {
        height: 32px;
        padding-inline: 24px;
    }

    svg {
        position: absolute;

        path {
            stroke-width: 0.5px;
            stroke: $neutralMainColor2;
        }
    }

    .contactUsContainer {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: space-evenly;

        .part {
            flex: 1;
            display: flex;
            position: relative;

            .word {
                position: absolute;
                top: 20px;
                font-size: 24px;
                line-height: 29px;

                @media (max-width: $screenWidthSmallFont) {
                    top: auto;
                    right: auto;
                    left: auto;
                    bottom: auto;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                }
            }

            &:first-child {
                text-align: right;

                img {
                    &:nth-child(2) {
                        flex: 1;
                    }
                }

                .word {
                    right: 44px;
                    font-weight: 600;

                    @media (max-width: $screenWidthSmallFont) {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;
                        right: auto;
                    }
                }
            }

            &:last-child {
                text-align: left;

                .word {
                    left: 21px;
                    color: $neutralMainColor1;

                    display: flex;
                    gap: 20px;
                    font-weight: 400;

                    @media (max-width: $screenWidthSmallFont) {
                        font-weight: 500;
                        font-size: 9px;
                        line-height: 11px;
                        left: auto;
                        gap: 5px;

                        .normalButton {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}