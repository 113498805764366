#getToKnowUs {
    .sticky.visitUs {
        padding-top: 48px;

        h1 {
            color: $neutralMainColor5;
            margin-left: calc(var(--default-padding-left) * -1);

            .title svg {
                rotate: 73rad;
                left: 87px;
                top: 17px;

                @media (max-width: $screenWidthSmallFont) {
                    left: 43px;
                    top: 3px;
                }
            }
        }

        .lineL {
            position: absolute;

            .line {
                stroke: $neutralMainColor2;
            }
        }
    }
}

.visitUsContainer {
    position: relative;
    padding-right: var(--default-padding-right);
    padding-bottom: 32px;

    @media (max-width: $screenWidthSmallFont) {
        padding-right: var(--default-padding-left-mobile);
    }
}

.mapContainer {
    position: relative;
    border-radius: 0px 0px 40px 40px;
    overflow: hidden;

    width: 100%;
    aspect-ratio: 1131 / 536;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
        border-radius: 0px 0px 40px 40px;
        pointer-events: none;

        width: 100%;
        aspect-ratio: 1131 / 536;
    }

    .mapImage {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
    }
}

#visitUsCards {
    position: relative;
}

.visitUsCard {
    position: absolute;
    display: none;

    .mapImage,
    .infoContainer {
        animation: fadeOut 0.5s ease-in-out forwards;
    }

    &.show {
        display: block;
        position: relative;

        .mapImage,
        .infoContainer {
            animation: fadeIn 0.5s ease-in-out forwards;
        }
    }
}

#mapPagination {
    right: var(--default-padding-right);
    bottom: 7px;

    @media (max-width: $screenWidthSmallFont) {
        right: var(--default-padding-left-mobile);
    }
}

.leftBorder {
    border-left: 0.5px solid $neutralMainColor2;

    @media screen and (max-width: $screenWidthSmallFont) {
        border-left: 0;   
    }
}

.noLeftBorderMidSize {
    @media screen and (max-width: 1024px) {
        border-left: 0;   
    }
}

.infoContainer {
    margin-inline: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: $screenWidthSmallFont) {
        margin-inline: var(--default-padding-left-mobile);
    }

    .iRow {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @media (max-width: $screenWidthSmallFont) {
            flex-wrap: wrap;
        }

        &.flexNoWrap {
            flex-wrap: nowrap;
        }
    }

    .iCol {
        display: flex;
        flex-direction: column;
    }

    h4 {
        @include fontSizeBody();
        font-weight: 700;
        text-transform: uppercase;

        color: $neutralMainColor5;
    }

    p {
        @include fontSizeBodyMobile();
        margin-bottom: 0;
    }

    .iContentContainer {
        padding-inline: 14px;
        min-width: 200px;

        @media (max-width: $screenWidthSmallFont) {
            min-width: auto;
        }
    }

    .icon {
        padding-right: 5px;
    }

    .socmed {
        padding-bottom: 10px;
        align-items: center;
    }
}
