$welcomeTextLeftPosition: 142px;

#landing {
    padding-top: 160px;
    overflow: hidden;

    @media (max-width: $screenWidthSmallFont) {
        padding-top: 60px;
        height: 640px;

        // #homepageSlide {
        //   background-image: url('./../../../img/page/home/landing/mainPattern1-mobile.png');
        //   background-repeat: no-repeat;

        //   #mask {
        //     width: 293px;
        //     height: 392px;
        //     background-image: url('./../../../img/page/home/landing/base-mobile.svg');
        //     background-repeat: no-repeat;
        //   }
        // }
    }

    .socmedIcon {
        width: 28px;
        height: auto;

        button {
            width: 100%;
            padding: 0;

            img {
                width: 100%;
            }
        }
    }

    #welcome {
        position: relative;
        width: 100%;
        height: 332px;

        overflow: hidden;
        pointer-events: none;

        .wContainer {
            position: absolute;
            width: 1221px;
            height: 332px;
            margin-left: 0;
            left: 1;

            @media (max-width: 1043px) {
                left: auto;
                right: -194px;
            }

            @media(max-width: 936px) {
                left: -107px;
                right: auto;
            }

            @media(max-width: $screenWidthSmallFont) {
                left: -84px;
            }

            #dotYellow {
                position: absolute;
                width: 48px;
                height: 48px;
                top: 15.5px;
                left: 214px;
                background-color: $primaryColor;
                border-radius: 24px;
            }

            #dotBlue {
                position: absolute;
                width: 48px;
                height: 48px;
                bottom: 0.5px;
                left: 214px;
                background-color: $accentColor;
                border-radius: 24px;
                z-index: 2;
            }

            .hLine1 {
                @include lineComponentAbsolutePosition(119.5px, 0)
            }

            .hLine2 {
                @include lineComponentAbsolutePosition(auto, auto, 2px, 140.5px)
            }

            .hLine3 {
                @include lineComponentAbsolutePosition(auto, 214px + 24px, auto, 24.87px)
            }

            .vLine1 {
                @include lineComponentAbsolutePosition(71.5px, $welcomeTextLeftPosition + 619px)
            }

            .vLine2 {
                @include lineComponentAbsolutePosition(47.5px, $welcomeTextLeftPosition + 678px)
            }

            .crossHLine {
                @include lineComponentAbsolutePosition(15.5px + 24px, 214px - 24px, auto, auto);
            }

            .crossVLine {
                @include lineComponentAbsolutePosition(1px, 214px + 24px, auto, auto);
            }

            #welcomeText {
                position: relative;
                left: $welcomeTextLeftPosition;
                top: 43.5px;
                font-family: 'Baskerville Normal', serif;
                font-style: normal;
                font-weight: 400;
                text-transform: uppercase;

                display: flex;
                align-items: center;

                #wText {
                    font-size: 204px;
                    line-height: 233px;
                    pointer-events: auto;
                }

                #elcomeText {
                    font-size: 110px;
                    line-height: 126px;
                    letter-spacing: 0.04em;
                    pointer-events: auto;
                }

                // @media (max-width: 780px) {
                //     #wText {
                //         font-size: 120px;
                //     }
                
                //     #elcomeText {
                //         font-size: 75px;
                //     }
                // }
            }
        }

        @media (max-width: $screenWidthSmallFont) {
            transform-origin: top left;
            transform: scale(0.44);
            width: 1221px;
            top: 35px;
            z-index: 2;
        }
    }

    #leftBackground {
        position: absolute;
        width: 100%;
        height: 233px;
        left: 0px;
        bottom: 0px;

        background: $neutralMainColor5;
        border-radius: 120px 0px 0px 0px;

        @media (max-width: $screenWidthSmallFont) {
            height: 200px;
            border-radius: 80px 0px 0px 0px;
        }
    }

    #homepageSlideNav {
        left: -22px - 40px;
        top: 160px + 192px + 97px;

        @media (max-width: $screenWidthSmallFont) {
            left: -44px;
            top: 368px;
        }
    }

    #homepageSlide {
        position: relative;
        width: 788px;
        height: 584px;
        margin-right: 0;
        margin-left: auto;

        top: -125px;

        background-image: url('./../../../img/page/home/landing/mainPattern1-csmall.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom 30px left;
        border-top-left-radius: 20%;

        @include hoverGlow(1300px, 0.2);
        &::before {
            z-index: 1;
        }

        .slideContainer {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }

        #mask {
            left: -1px;
            width: 788px;
            height: 480px;
            background-image: url('./../../../img/page/home/landing/base.svg');
            background-repeat: no-repeat;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            pointer-events: none;
        }

        .vLine3 {
            @include lineComponentAbsolutePosition(auto, 0, auto, 0, $neutralMainColor3, 1px)
        }

        #homepageSlideTitle {
            height: 100px;
            padding-top: 20px;
            padding-left: 54px;

            @include fontSubheading2();

            #to {
                font-style: italic;
            }

            #gilgalPik {
                font-style: normal;
            }
        }

        #homepageSlideContent {
            padding: 36px 62px;
            height: 380px;

            #motto {
                @include fontScriptType();
                font-size: 46px;
            }

            #content {
                margin-top: 28px;
                width: 431px;
                position: relative;

                .slide {
                    position: absolute;
                    animation: fadeOut 0.5s ease-in-out forwards;

                    &.show {
                        animation: fadeIn 0.5s ease-in-out forwards;
                    }
                }
            }
            
            .uniqueButton {
                position: absolute;
                bottom: 89px;
            }
        }

        @media (max-width: 936px) {
            left: 131px;
            width: auto;
        }

        @media (max-width: $screenWidthSmallFont) {
            margin-left: 0;
            left: 67px;
            top: -201px;
            height: 392px;
            background-image: url('./../../../img/page/home/landing/mainPattern1-mobile.png');

            #mask {
                background-image: url('./../../../img/page/home/landing/base-mobile.svg');
                left: -5px;
                top: -1px;
                width: 298px;
                height: 314px;
            }

            #homepageSlideTitle {
                height: 40px;
                padding-top: 10px;
                padding-left: 20px;
            }

            #homepageSlideContent {
                padding: 12px 20px;

                #content {
                    margin-top: 20px;
                    width: 180px;
                }

                .uniqueButton {
                    bottom: 154px;
                    transform: scale(0.8);
                    transform-origin: left top;
                }
            }

            .socmedIcon {
                transform: scale(0.7);
                left: -38px;
                top: 64px;
            }
        }
    }
}