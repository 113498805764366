#getToKnowUs {
    .sticky.ourLeaders {
        position: relative;
        padding: 0;

        .pad {
            padding-left: var(--default-padding-left);
            padding-right: var(--default-padding-right);

            @media (max-width: 1024px) {
                padding-left: calc(var(--default-padding-left) - 60px);
            }

            @media (max-width: $screenWidthSmallFont) {
                padding-inline: var(--default-padding-left-mobile);
            }
        }

        h1 {
            color: $neutralMainColor5;

            @media (max-width: $screenWidthSmallFont) {
                margin-bottom: 7px;
            }
        }

        .lineL,
        .lineR {
            position: absolute;

            .line {
                stroke: $neutralMainColor3;
                stroke-width: 0.5px;
            }
        }

        .lineL {
            left: var(--default-padding-left);

            @media (max-width: 1024px) {
                left: calc(var(--default-padding-left) - 60px);
            }

            @media (max-width: $screenWidthSmallFont) {
                left: var(--default-padding-left-mobile);
            }
        }

        .lineR {
            right: var(--default-padding-right);

            @media (max-width: $screenWidthSmallFont) {
                right: var(--default-padding-left-mobile);
            }
        }

        .section {
            height: fit-content;

            &.header {
                padding: 104px 92px 136px 92px;
                text-align: left;

                @media (max-width: $screenWidthSmallFont) {
                    padding: 32px calc(var(--default-padding-left-mobile) + 10px) 20px 10px;
                    text-align: center;
                }

                p, pre {
                    @include fontLibreItalic();
                    margin-top: 18px;
                    color: black;
                    white-space: pre-wrap;
                    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                    white-space: -pre-wrap;      /* Opera 4-6 */
                    white-space: -o-pre-wrap;    /* Opera 7 */
                    word-wrap: break-word;       /* Internet Explorer 5.5+ */

                    @media (min-width: ($screenWidthSmallFont + 1)) {
                        max-width: 639px;
                    }
                }
            }
        }
    }
}

.ourLeaders {
    h2 {
        @include fontSubheading2();
        color: $neutralMainColor5;
        text-transform: capitalize;
    }
}

.leaderCardsContainer {
    position: relative;

    .leaderCards {
        .leaderCardContainer {
            position: relative;

            .leaderCardContent {
                padding: 56px 0 56px 51px;

                @media (max-width: $screenWidthSmallFont) {
                    padding-inline: 0;
                    padding-block: 16px 24px;
                }
            }

            h2 {
                margin-left: 41px;
                margin-bottom: 0;

                @media (max-width: $screenWidthSmallFont) {
                    margin-inline: auto;
                    text-align: center;
                }
            }

            .title {
                @include fontScriptType();
                color: black;
                margin-left: 41px;
                margin-bottom: 22px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-bottom: 12px;
                    margin-inline: auto;
                    text-align: center;
                }
            }

            .leaderCard {
                position: relative;
                border-radius: 0px 0px 40px 40px;
                overflow: hidden;

                @media (max-width: $screenWidthSmallFont) {
                    border-radius: 0px 0px 20px 20px;
                }

                .outline {
                    box-shadow: inset 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
                    border-radius: 0px 0px 40px 40px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    pointer-events: none;

                    @media (max-width: $screenWidthSmallFont) {
                        box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
                        border-radius: 0px 0px 20px 20px;
                    }
                }

                .data {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    @media (max-width: $screenWidthSmallFont) {
                        flex-direction: column;
                    }

                    .image {
                        background-color: $neutralMainColor3;
                        min-width: 440px;
                        min-height: 490px;
                        aspect-ratio: 1/1;
                        flex: 1;
                        background-size: cover;
                        background-position: top center;

                        @media (max-width: $screenWidthSmallFont) {
                            min-width: 100%;
                            min-height: 192px;
                        }
                    }

                    .text {
                        flex: 1;
                        padding: 25px 31px;

                        @media (min-width: ($screenWidthSmallFont + 1)) {
                            min-width: 440px;
                            padding: 90px 10% 103px 10%;
                        }

                        .link {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &.style2 {
            .leaderCardContainer {
                .background {
                    display: none;
                }

                .leaderCardContent {
                    padding: 64px 110px 64px 160px;

                    @media (max-width: 1024px) {
                        padding: 64px 60px 64px 100px;
                    }

                    @media (max-width: $screenWidthSmallFont) {
                        padding-inline: 37px;
                        padding-block: 16px 24px;
                    }
                }

                h2 {
                    font-size: 30px;
                    line-height: 37px;

                    @media (max-width: $screenWidthSmallFont) {
                        font-size: 20px;
                        line-height: 23px;
                    }
                }

                .title {
                    font-size: 24px;
                    line-height: 43px;

                    @media (max-width: $screenWidthSmallFont) {
                        font-size: 14px;
                        line-height: 25px;
                        letter-spacing: -0.025em;
                    }
                }

                .leaderCard {
                    .data {
                        .image {
                            min-width: 420px;
                            min-height: 470px;

                            @media (max-width: $screenWidthSmallFont) {
                                min-width: 100%;
                                min-height: 192px;
                            }
                        }

                        .text {
                            @media (min-width: ($screenWidthSmallFont + 1)) {
                                min-width: 360px;
                                padding: 80px 6% 93px 6%;
                            }
                        }
                    }
                }
            }
        }

    }
}

.shadowOutline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.3125) 44.79%, rgba(217, 217, 217, 0) 100%);

    @media (max-width: $screenWidthSmallFont) {
        height: 16px;
    }

    &.last {
        top: auto;
        bottom: -32px;

        @media (max-width: $screenWidthSmallFont) {
            bottom: -16px;
        }
    }
}