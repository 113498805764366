#getToKnowUs {
    .sticky.fivePilars {
        padding-top: 48px;

        h1 {
            color: $neutralMainColor5;
            margin-left: calc(var(--default-padding-left)*-1);

            .title svg {
                rotate: 73rad;
                left: 219px;
                top: 22px;

                @media (max-width: $screenWidthSmallFont) {
                    left: 109px;
                    top: 5px;
                }
            }
        }

        .lineL {
            position: absolute;

            .line {
                stroke: $neutralMainColor2;
            }
        }
    }
}

#fivePilarCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 9px;
    margin-top: 88px;
    padding-bottom: 80px;
    justify-content: center;
    padding-right: var(--default-padding-right);

    @media (max-width: $screenWidthSmallFont) {
        padding-right: var(--default-padding-left-mobile);
        flex-direction: column;
    }

    .fRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 9px;
        justify-content: center;

        @media (max-width: $screenWidthSmallFont) {
            flex-direction: column;
        }
    }

    .fCard {
        display: flex;
        flex-direction: column;
        padding: 48px 21px;
        width: 219px;
        background-color: $neutralMainColor5;

        @media (max-width: $screenWidthSmallFont) {
            flex-direction: row;
            width: 100%;
            padding: 13px 20px;
            gap: 19px;
        }

        &.first {
            border-top-left-radius: 60px;

            @media (max-width: $screenWidthSmallFont) {
                border-top-left-radius: 40px;
            }
        }
        &.last {
            border-bottom-right-radius: 60px;

            @media (max-width: $screenWidthSmallFont) {
                border-bottom-right-radius: 40px;
            }
        }

        .imageContainer {
            position: relative;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 24px;

            @media (max-width: $screenWidthSmallFont) {
                margin: auto;
            }

            .outline {
                position: absolute;
                width: 100%;
                aspect-ratio: 1;
                border-radius: 50%;
                box-shadow: inset 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
                pointer-events: none;
            }

            .image {
                width: 100%;
                background-size: cover;
                background-position: center center;
                aspect-ratio: 1;
            }
        }

        .dataContainer {
            color: $neutralMainColor2;

            h2 {
                @include fontLibreNormal();
                color: white;
                display: flex;
                align-items: flex-start;
                height: 72px;
                justify-content: flex-end;
                flex-direction: column;

                @media (max-width: $screenWidthSmallFont) {
                    height: auto;
                    margin-bottom: 0;
                }
            }

            .link {
                color: white;
                margin-bottom: 0;
            }
        }
    }
}