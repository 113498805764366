$maxWidth: 1440px;

:root {
    --default-padding-left-mobile: 25px;
    --default-padding-left: 240px;
    --default-padding-right: 68px;
}

html {
    scroll-behavior: smooth;
}

.app {
    position: relative;
    max-width: $maxWidth;
    width: 100%;
    margin: auto;

    background-image: url("./../img/background-csmall.jpg");
    background-repeat: repeat-y;
    min-height: 100vh;
    // overflow: hidden;
}

body {
    @include fontSizeBody();
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    color: $neutralMainColor5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $neutralMainColor5;
}

h2 {
    font-size: 38px;
    line-height: 41px;
    letter-spacing: 0.015em;
    text-transform: uppercase;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.025em;
    }
}

h3 {
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;

    @media (max-width: $screenWidthSmallFont) {
        font-size: 16px;
        line-height: 20px;
    }
}

p {
    margin-bottom: 24px;

    .firstCharacter {
        @include fontLibreNormal();
    }
}

.firstCharacter2 {
    @include fontSubheading2();
    line-height: 44px;
}

.link {
    text-decoration: none;
    cursor: pointer;
}

a.link {
    display: block;
    font-weight: 700;
    text-transform: uppercase;

    cursor: pointer;

    margin-bottom: 30px;
    text-decoration: none;

    color: $neutralMainColor5;

    @media (max-width: $screenWidthSmallFont) {
        margin-bottom: 41px;
    }
}

.socmedIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;

    position: absolute;
    width: 24px;
    height: 192px;
    left: -30px - 24px;
    top: 160px;
}

.pagination {
    position: absolute;
    width: 40px;
    height: 14.45px;

    display: flex;
    justify-content: space-between;

    button {
        display: contents;
    }

    @media (max-width: $screenWidthSmallFont) {
        transform: scale(0.5);
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.section {
    width: 100%;
    height: 1024px;
    position: relative;
}

.mobileHide {
    @media (max-width: $screenWidthSmallFont) {
        display: none;
    }
}

.anchorPoint {
    position: absolute;
    top: -96px;
}

.emptySpace {
    background-color: $neutralMainColor5;
}

.clickable {
    cursor: pointer;
}

#backToTop {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg .backToTopColor {
        fill: $neutralMainColor2;
    }

    &:hover {
        svg .backToTopColor {
            fill: $primaryColor;
        }
    }

    &.hide {
        display: none;
    }
}

#unsubscribe {
    padding-top: 160px;
    width: 100%;
    max-width: 800px;
    padding-inline: 60px;
    margin: auto;

    @media (max-width: $screenWidthSmallFont) {
        padding-top: 60px;
        padding-inline: 25px;
    }

    .unsubscriberContainer {
        border: 1px solid $neutralMainColor2;
        border-radius: 5px;
        padding: 20px;
        text-align: center;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: $neutralMainColor2;
    transition: background-color 0.5s ease-in-out;
    margin-block: 10px;
}

::-webkit-scrollbar-thumb {
    background: $neutralMainColor3;
}

@supports (scrollbar-color: red blue) {
    * {
        scrollbar-color: $neutralMainColor3 $neutralMainColor2;
        scrollbar-width: thin;
    }
}

#hideTopMenuBlock {
    position: fixed;
    top: 0;
    width: 100%;
    height: 96px;
    background-color: $neutralMainColor5;
    z-index: 2;
}