#letsGrowMenu {
    position: fixed;
    top: 96px;
    left: inherit;
    width: 322px;
    height: 384px;
    padding: 64px 72px 0px 72px;

    background-image: url('./../../../img/page/getToKnowUs/baseMenu.svg');
    background-repeat: no-repeat;
    background-position-y: -1px;;
    line-height: 17px;
    letter-spacing: 0.025em;
    z-index: 2;

    ul.menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 40px;
        list-style-type: none;
        margin: 0;

        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 8px;
            gap: 8px;
        
            border-left: 1px solid $neutralMainColor5;

            a {
                text-decoration: none;
                color: inherit;
                
                &:hover, &.selected {
                    font-weight: 700;
                }
            }
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        top: 62px;
        width: 100%;
        height: auto;
        background-image: none;
        padding: 0;
        font-size: 9px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.02em;
        background-color: $neutralMainColor5;

        ul.menu {
            flex-direction: row;
            width: 100%;
            gap: inherit;
            justify-content: center;
            
            li{
                padding: 7px 0px;
                justify-content: center;
                flex: 1;
                border: 0.5px solid $neutralMainColor3;
                box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
                background-color: $neutralMainColor1;
                
                &:first-child {
                    border-bottom-left-radius: 22px;
                }
                
                &:last-child {
                    border-bottom-right-radius: 22px;
                }
            }
        }
    }
}