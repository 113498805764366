#popupArtCreative {
    position: relative;
    padding-left: var(--default-padding-left);
    padding-right: var(--default-padding-right);

    .backgroundArtCreative {
        position: relative;
        background-color: $neutralMainColor1;
        width: 100%;
        height: fit-content;
        border-top-right-radius: 80px;
        border-bottom-left-radius: 80px;
        box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.30) inset;
        padding-block: 50px;
    }

    .shapeCircle {
        background-color: $primaryColor;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.30) inset;

        &.s40 {
            width: 40px;
            height: 40px;
        }

        &.s80 {
            width: 80px;
            height: 80px;
        }
    }

    .title {
        font-family: "Baskerville Normal", serif;
        font-weight: 400;
        font-size: 20px;
        font-style: normal;
        text-transform: uppercase;

        .firstCharacter {
            @include fontSubheading2();
        }
    }
}