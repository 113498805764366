#getConnected {
    height: 915px; // $normalComponentHeight;
    @include sectionPadding(24px 0 24px 25px);
    overflow-x: clip;

    h1 {
        @include title($neutralMainColor5, left, $neutralMainColor4, 12px, 142px, 0, auto);

        img {
            left: 142px;

            @media (max-width: $screenWidthSmallFont) {
                left: 72px;
            }
        }
    }

    .imageContainer {
        mask: url('./../../../img/card/component2Mask.svg') no-repeat;
        max-width: 928px;
        width: 72%; // previous value: 100%
        height: 608px;
        margin-bottom: 64px;

        background-size: cover;
        background-position: top center;

        // img {
        //     width: auto;
        //     height: 100%;
        // }
    }

    @media (max-width: $screenWidthSmallFont) {
        height: 598px;

        .imageContainer {
            height: 352px;
            width: 100%;
        }
    }

    .contactUsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 21px;

        position: absolute;
        width: 551px;
        height: 32px;
        bottom: 52px;

        span {
            &:first-child {
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.03em;
            }

            &:nth-child(2) {
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.025em;
            }
        }

        @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 1088px) {
            height: 64px;
            width: 336px;
            justify-content: left;
            align-items: flex-start;
            gap: 10px;
            flex-wrap: wrap;
        }

        @media (max-width: $screenWidthSmallFont) {
            width: 100%;
            height: 40px;
            bottom: 0;
            gap: 12px;
            left: 0;

            span {
                &:first-child {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                }

                &:nth-child(2) {
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 11px;
                }
            }
        }
    }

    .rightSection {
        position: absolute;
        width: 524px;
        height: 1088px; //1088
        right: 0;
        top: 0;

        padding: 160px+96px 59px 0 82px+76px;

        background-image: url('./../../../img/page/home/getConnected/mainPattern-csmall.png'), url('./../../../img/page/home/getConnected/base-csmall.png');
        background-position: bottom right, top right;
        background-repeat: no-repeat;

        color: $neutralMainColor1;

        z-index: 1;

        @include hoverGlow(1000px, 0.2);
        &::before {
            z-index: 0;
            pointer-events: none;
        }

        h3 {
            margin-bottom: 22px;
        }

        .link {
            color: $neutralMainColor1;
            margin-bottom: 34px;
        }

        .find {
            color: $neutralMainColor5;
            background-color: $neutralMainColor1;
        }

        @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 976px) {
            right: auto;
            left: 441px; // previous value: 46%;
            padding-left: 14%;
            padding-right: 16%;
        }

        @media (min-width: ($screenWidthSmallFont + 1px)) and (max-width: 850px) {
            left: 420px;
            padding-right: 25%;
        }

        @media (max-width: $screenWidthSmallFont) {
            background-color: $neutralMainColor5;
            background-image: url('./../../../img/page/home/getConnected/mainPattern-mobile.png');
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            min-height: 136px;
            height: auto;
            border-radius: 30px 0px 0px 30px;
            top: auto;
            bottom: 40px;
            padding: 20px 25px;

            h3, .link {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}