#ourService {
    @include sectionPadding();
    height: 928px;
    border-radius: 0px 0px 0px 120px;

    background: $neutralMainColor5 url('./../../../img/page/home/ourService/mainPattern2-csmall.png') no-repeat;

    @include hoverGlow(1300px, 0.2);
    
    @media (max-width: $screenWidthSmallFont) {
        height: auto;
        border-radius: 0px 0px 0px 40px;
        background-image: url('./../../../img/page/home/ourService/mainPattern2-mobile.png');
    }

    .vLine1 {
        @include lineComponentAbsolutePosition(0, 70px, auto, auto, $neutralMainColor2, 0.5px);

        @media (max-width: $screenWidthSmallFont) {
            @include lineComponentAbsolutePosition(0, 23px, auto, auto, $neutralMainColor2, 0.5px);
        }
    }

    h1 {
        @include title($neutralMainColor1, right, $neutralMainColor2, 12px, 0, 241px, auto);
        position: relative;
        overflow: hidden;

        img {
            left: 7px;

            @media (max-width: $screenWidthSmallFont) {
                left: 5px;
            }
        }
    }

    #ourServiceCards {
        position: relative;
    }

    .serviceCard {
        position: absolute;
        display: none;

        .cardImage, .cardContent > h2, .date {
            animation: fadeOut 0.5s ease-in-out forwards;
        }
        
        &.show {
            display: block;

            .cardImage, .cardContent > h2, .date {
                animation: fadeIn 0.5s ease-in-out forwards;
            }
        }

        @media (max-width: $screenWidthSmallFont) {
            position: relative;
        }
    }

    .card {
        width: 100%;
        height: 648px;
        background: $neutralMainColor1;

        box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 80px 80px;
        overflow: hidden;

        @media (max-width: $screenWidthSmallFont) {
            height: auto;
            box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.25);
            border-radius: 0px 0px 30px 30px;
            overflow: visible;
        }

        .cardImage {
            background-image: url('./../../../img/card/example.png');
            height: 648px;
            width: 100%;
            width: fill-available;
            position: absolute;
            top: -1px;
            right: -1px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 1;
            pointer-events: none;

            mask: url('./../../../img/card/component1Mask.svg') no-repeat;
            mask-size: auto 648px;
            mask-position: top right;

            @media (max-width: 1310px) {
                mask-position: top left;
            }

            @media (max-width: $screenWidthSmallFont) {
                mask: none;
                aspect-ratio: 1;
                background-position: center center;
                width: 100%;
                position: relative;
                top: 0;
                right: 0;
                height: auto;

            }
        }

        .cardContent {
            padding-top: 87px;
            padding-left: 64px;

            @media (max-width: $screenWidthSmallFont) {
                padding: 24px;
            }

            .date {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .left {
                    @include fontDateDay();
                    color: $neutralMainColor4;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;

                    @media (max-width: $screenWidthSmallFont) {
                        flex-direction: row;
                        font-size: 8px;
                        line-height: 10px;
                        gap: 3px;
                    }
                }
            }

            .serviceTitle {
                max-width: 306px;
                height: 131px;

                margin-top: 28px;
                margin-bottom: 12px;

                width: min-content;
                min-width: 26%;
                position: relative;

                @media (max-width: $screenWidthSmallFont) {
                    height: auto;
                    margin-top: 0;
                    margin-bottom: 0px;
                    width: 100%;
                    min-width: auto;
                    max-width: 100%;
                    
                    .line {
                        width: calc(100% + 48px);
                        position: absolute;
                        bottom: 0;
                        left: -24px;
                        border: 1px solid $neutralMainColor2;
                    }
                }
            }

            .ministryContainer {
                .header {
                    margin-left: -64px;
                    margin-bottom: 10px;
                    background: url('./../../../img/card/base2.svg') no-repeat;
                    padding-top: 42px;
                    padding-bottom: 12px;
                    position: relative;

                    .ministryTitle {
                        margin-left: 64px;
                    }

                    @media (max-width: $screenWidthSmallFont) {
                        padding-top: 13px;
                        padding-bottom: 2px;
                        margin-left: -23px;
                        margin-bottom: 0;
                        background: none;

                        .ministryTitle {
                            margin-left: 23px;
                        }
                    
                        .line {
                            width: calc(100% + 24px);
                            position: absolute;
                            bottom: 0;
                            border: 1px solid $neutralMainColor2;
                        }
                    }
                }

                p {
                    width: 307px;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 163px;
                        margin-bottom: 0;
                    }
                }

                .serviceDetail {
                    .detail {
                        .title {
                            font-weight: 700;
                            text-transform: uppercase;
                        }

                        a {
                            cursor: pointer;
                            text-decoration: none;
                            color: $neutralMainColor5;
                        }
                    
                        @media (max-width: $screenWidthSmallFont) {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    #ourServicePageNav {
        right: 150px;
        bottom: 55px;

        @media (max-width: $screenWidthSmallFont) {
            right: 75px;
            bottom: 9px;
        }
    }
}