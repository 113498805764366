#waterBaptism {
    .padContainer {
        @media (max-width: $screenWidthSmallFont) {
            padding-inline: 55px;
        }
    }

    .baptismData {
        gap: 30px;
        margin-bottom: 100px;
        align-items: center;

        @media (max-width: $screenWidthSmallFont) {
            margin-bottom: 32px;
        }
        
        .image {
            flex: 1;
            min-height: 600px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            @media (max-width: 1024px) {
                display: none;
            }
        }

        .flexColumn {
            flex: 1;
            margin-right: 67px;

            @media (max-width: 1024px) {
                margin-right: 0;
            }

            @media (max-width: $screenWidthSmallFont) {
                align-items: center;
                position: relative;
                width: 100%;
            }

            p {
                margin-bottom: 64px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-bottom: 32px;
                }
            }

            .imageMobile {
                display: none;

                @media (max-width: 1024px) {
                    width: 210px;
                    height: auto;
                    min-height: 290px;
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    margin-bottom: 64px;
                }

                @media (max-width: $screenWidthSmallFont) {
                    margin-bottom: 32px;
                }
            }

            .bibleVerse {
                margin-bottom: 64px;
                @include fontLibreItalic();

                @media (max-width: $screenWidthSmallFont) {
                    margin-bottom: 32px;
                }

                .verse {
                    display: block;
                    font-family: inherit;
                    font-size: 9px;
                    line-height: 14px;
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}