/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Baskerville Normal';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Normal'), url('baskvl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Regular'), url('baskervi.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville Light-Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Light-Italic'), url('BASKE10.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville Light';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Light'), url('BASKE9.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville LightA';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville LightA'), url('BASKRV_L.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Bold'), url('BASKE1.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville Bold'), url('Baskerville Bold font.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville BT Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville BT Roman'), url('BaskervilleBT.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville BT Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville BT Italic'), url('BaskervilleItalicBT.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville BT Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville BT Bold'), url('BaskervilleBoldBT.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville BT Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville BT Bold'), url('baskerville bold bt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Baskerville BT Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Baskerville BT Bold Italic'), url('BaskervilleBoldItalicBT.woff') format('woff');
    }