@mixin sectionPadding($customMobilePadding: null) {
    padding: 64px 70px;

    @media (max-width: $screenWidthSmallFont) {
        @if($customMobilePadding){ padding: $customMobilePadding; }
        @else { padding: 24px 25px; }
    }
}

@mixin lineComponentAbsolutePosition($top: auto, $left: auto, $right: auto, $bottom: auto, $color: $neutralMainColor5, $width: 0.5px) {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;

    .line {
        stroke: $color;
        stroke-width: $width;
    }
}

@mixin title($color: $neutralMainColor1, $align: right, $borderColor: $neutralMainColor2, $top: auto, $left: auto, $right: auto, $bottom: auto) {
    text-align: $align;
    color: $color;
    @include fontTitle();
    position: relative;

    margin-bottom: 52px;

    .h1Border {
        position: absolute;
        top: $top;
        left: $left;
        right: $right;
        bottom: $bottom;

        height: 32px;

        @if $align ==right {
            .h1hLine1 {
                @include lineComponentAbsolutePosition(0, 0, auto, auto, $borderColor, 0.5px);
            }

            .h1hLine2 {
                @include lineComponentAbsolutePosition(auto, 0, auto, 0, $borderColor, 0.5px);
            }
        }

        @else {
            .h1hLine1 {
                @include lineComponentAbsolutePosition(0, auto, 0, auto, $borderColor, 0.5px);
            }

            .h1hLine2 {
                @include lineComponentAbsolutePosition(auto, auto, 0, 0, $borderColor, 0.5px);
            }
        }

        @media (max-width: $screenWidthSmallFont) {
            width: 100%;
            top: 5px;
            height: 18px;
            left: 0;
            right: 0;
        }
    }

    .title {
        position: relative;
        display: flex;
        width: fit-content;
        margin: auto;

        @if $align ==right {
            margin-right: 0;
        }

        @else {
            margin-left: 0;
        }

        align-items: center;

        img {
            position: absolute;

            @media (max-width: $screenWidthSmallFont) {
                width: 11px;
            }
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        font-size: 23px;
        line-height: 28px;

        margin-bottom: 24px;
    }
}

@mixin titleSVG($color: $neutralMainColor1, $align: right, $borderColor: $neutralMainColor2, $top: auto, $left: auto, $right: auto, $bottom: auto) {
    text-align: $align;
    color: $color;

    @include fontTitle();
    position: relative;

    margin-bottom: 52px;

    .h1Border {
        position: absolute;
        top: $top;
        left: $left;
        right: $right;
        bottom: $bottom;

        height: 32px;

        @if $align ==right {
            .h1hLine1 {
                @include lineComponentAbsolutePosition(0, 0, auto, auto, $borderColor, 0.5px);
            }

            .h1hLine2 {
                @include lineComponentAbsolutePosition(auto, 0, auto, 0, $borderColor, 0.5px);
            }
        }

        @else {
            .h1hLine1 {
                @include lineComponentAbsolutePosition(0, auto, 0, auto, $borderColor, 0.5px);
            }

            .h1hLine2 {
                @include lineComponentAbsolutePosition(auto, auto, 0, 0, $borderColor, 0.5px);
            }
        }

        @media (max-width: $screenWidthSmallFont) {
            width: 100%;
            top: 5px;
            height: 18px;
            left: 0;
            right: 0;
        }
    }

    .title {
        position: relative;
        display: flex;
        width: fit-content;
        margin: auto;

        @if $align ==right {
            margin-right: 0;
        }

        @else {
            margin-left: 0;
        }

        align-items: center;

        svg {
            position: absolute;

            @media (max-width: $screenWidthSmallFont) {
                width: 11px;
            }
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        font-size: 23px;
        line-height: 28px;

        margin-bottom: 24px;
    }
}