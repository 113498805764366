#getToKnowUs, #ministries, #letsGrow {
    .socmedContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
        gap: 32px;
        width: fit-content;

        .socmedIcon {
            position: relative;
            left: auto;
            top: auto;
        }
    }

    #contactUs {
        overflow: hidden;
        
        .contactUsContainer {
            .part {
                img {
                    filter: brightness(0);
                }

                &:first-child {
                    .word {
                        color: $neutralMainColor1;
                    }
                    
                }

                &:last-child {
                    .word {
                        color: $neutralMainColor5;
                    }
                }
            }
        }

        @media (min-width: ($screenWidthSmallFont + 1)) {
            display: none;
        }
    }
}

#getToKnowUs, #letsGrow {
    .socmedContainer {
        --width: 240px;
        position: fixed;
        top: 520px;
        width: var(--width);

        @media (min-width: ($screenWidthSmallFont+1)) and (max-width: 1024px) {
            width: calc(var(--width) - $mediumPaddingDiff);
        }

        @media (max-width: $screenWidthSmallFont) {
            display: none;
        }
    }
}