$stickyLeftPadding: 92px;
$stickyLeftPaddingMobile: 20px;
$mediumPaddingDiff: 60px;

#getToKnowUs {
    margin-top: 95px;

    .sticky {
        position: relative;
        padding-left: var(--default-padding-left);

        &>.background {
            position: absolute;
            width: 100%;
            height: calc(100% - 180px);
            background-color: $neutralMainColor5;
            margin-left: calc(var(--default-padding-left) * -1);
            border-radius: 0px 0px 0px 120px;

            @media (min-width: ($screenWidthSmallFont+1)) and (max-width: 1024px) {
                margin-left: calc((var(--default-padding-left) - $mediumPaddingDiff) * -1);
            }

            @media (max-width: $screenWidthSmallFont) {
                height: 1150px;
                margin-left: calc(var(--default-padding-left-mobile)*-1);
                border-radius: 0px 0px 30px 30px;
            }
        }

        .subheading {
            max-width: 100%;
            position: relative;
            overflow: hidden;
            @include fontSubheading2();
            
            .lineMobile {
                display: none;
            }
            .text {
                color: $neutralMainColor1;
                margin-bottom: 21px;
            }

            @media (max-width: $screenWidthSmallFont) {
                .lineMobile {
                    position: absolute;
                    display: block;
                    top:0px;
                    margin-left: 30px;

                    .line {
                        stroke-width: 0.5px;
                        stroke: $neutralMainColor1;
                        
                    }
                }
            }
        }

        &.ourVision {
            @media (min-width: ($screenWidthSmallFont + 1)) {
                .subheading {
                    .text {
                        padding-left: $stickyLeftPadding;
                        padding-top: 24px;
                    }
                }
            }
        }

        .subheading .text {
            padding-left: $stickyLeftPaddingMobile;
            padding-top: 9px;
            margin-bottom: 11px;
        }

        .subheadingVector {
            position: absolute;
            left: 0px;

            &:first-child { // desktop
                display: block;
            }

            &:nth-child(2) { // mobile
                display: none;
            }

            @media (max-width: $screenWidthSmallFont) {
                &:first-child { // desktop
                    display: none;
                }

                &:nth-child(2) { // mobile
                    display: block;
                }
            }
        }

        .content {
            padding-left: $stickyLeftPadding;
            padding-bottom: 68px;
            position: relative;

            img {
                position: absolute;
                // left: 621px;
                top: -58px;
                right: -20px;

                @media (max-width: 1235px) {
                    right: auto;
                    left: 412px;
                }

                @media (max-width: 987px) {
                    left: auto;
                    right: -250px;
                }

                @media (max-width: $screenWidthSmallFont) {
                    right: 0;
                    top: 9px;
                }

                &:first-child { // desktop
                    display: block;
                }

                &:nth-child(2) { // mobile
                    display: none;
                }
            }

            &.salvation {
                overflow-x: clip;
                
                .contentBox {
                    padding: 30px 99px 96px 70px;
                    max-width: 690px;
                    height: 592px;
                    width: 100%;
                    border-bottom-right-radius: 70%;

                    @media (max-width: $screenWidthSmallFont) {
                        padding-left: 0;
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                }
            }

            .contentBox {
                

                p{
                    color: $neutralMainColor1;

                    &.ourVisionSubheading {
                        @include fontLibreItalic();
                    }

                    &:last-child {
                        max-width: 83%;
                    }
                }

                @media (max-width: $screenWidthSmallFont) {
                    padding-bottom: 0;
                    height: auto;
                }
            }

            .line {
                stroke-width: 0.5px;
                stroke: $neutralMainColor3;
                left: 0px;
                top: 41px;
                position: absolute;
            }

            @media (max-width: $screenWidthSmallFont) {
                padding-left: $stickyLeftPaddingMobile;

                img {
                    &:first-child { // desktop
                        display: none;
                    }

                    &:nth-child(2) { // mobile
                        display: block;
                    }
                }

                &.salvation {
                    height: 429px;
                }
            }
        }

        .lineH {
            position: absolute;
            display: block;
            padding-bottom: 300px;
            
            .line {
                stroke-width: 0.5px;
                stroke: $neutralMainColor3;
            }

            @media (max-width: $screenWidthSmallFont) {
                margin-top: 18px;
                padding-bottom: 200px;
                
            }
        }

        @media (min-width: ($screenWidthSmallFont+1)) and (max-width: 1024px) {
            padding-left: calc(var(--default-padding-left) - $mediumPaddingDiff);
        }

        @media (max-width: $screenWidthSmallFont) {
            padding-left: var(--default-padding-left-mobile);
        }
    }

    h3 {
        color: $neutralMainColor1;
        text-transform: none;
        margin-bottom: 35px;
    }

    h1 {
        @include titleSVG($neutralMainColor1, right, $neutralMainColor4, 12px, 0, 241px, auto);
        color: $neutralMainColor5;
        margin-bottom: 0;
        padding-right: 68px;
        position: sticky;
        top: 96px;
        background-image: url('./../../../img/background-csmall.jpg');
        z-index: 2;
        overflow: hidden;

        svg {
            left: 7px;

            @media (max-width: $screenWidthSmallFont) {
                left: 5px;
            }
        }

        .iconSvg {
            .iconColor {
                fill: $neutralMainColor5;
            }
        }
    }

    @media (max-width: $screenWidthSmallFont) {
        h1 {
            background: none;
            color: $neutralMainColor1;
            position: relative;
            top: 13px;
            padding-right: var(--default-padding-left-mobile);
        }
    }
}

@media (max-width: $screenWidthSmallFont) {
    #getToKnowUs .sticky.ourVision h1 {
        .iconSvg {
            .iconColor{
                fill: $neutralMainColor1;
            }
        }
    }
}