.flexRow {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: $screenWidthSmallFont) {
        flex-direction: column;
        margin: auto;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
}


.flexNoWrap {
    flex-wrap: nowrap;
}

.flexInlinePadding {
    padding-inline: 48px;
}

.flexGap {
    @media (max-width: 1024px) {
        gap: 10px;
    }
}

.flexGap30 {
    gap: 30px;
}

.flexGap40 {
    gap: 40px;
}

.flexGap60 {
    gap: 60px;
}

.flex25 {
    flex-basis: 25%;
}

.flex50 {
    flex-basis: 50%;
}

.flex75 {
    flex-basis: 75%;
}

.flex100Mobile {
    @media (max-width: $screenWidthSmallFont) {
        flex-basis: 100%;
    }
}

.flexStart {
    align-items: flex-start;
}

.flexCenter {
    align-items: center;
}

.flexEnd {
    align-items: flex-end;
}

.flexJustifyStart {
    justify-content: flex-start;
}

.flexJustifyCenter {
    justify-content: center;
}