.newLifeCards {
    &.flexRow, .flexRow {
        gap: 8px;
    }
}

.newLifeCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 210px;
    height: 560px;
    margin-bottom: 26px;

    .image {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        flex: 1;
    }

    @media (max-width: $screenWidthSmallFont) {
        height: auto;

        .image {
            flex: none;
        }

        &.newBeginning .image {
            height: 80px;
        }

        &.newHeart .image {
            height: 133px;
        }

        &.newCreation .image {
            height: 217px;
        }

        &.newPower .image {
            height: 232px;
        }

        &.newRelationship .image {
            height: 225px;
        }
    }

    .contentContainer {
        height: 336px;
        background: $neutralMainColor1;
        box-shadow: 0px 10px 16px 6px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 20px 20px;
        padding: 20px;

        color: $neutralMainColor5;

        @media (max-width: $screenWidthSmallFont) {
            height: auto;
        }

        .title {
            font-family: "Libre Baskerville";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            text-transform: uppercase;
            margin-bottom: 26px;

            @media (max-width: $screenWidthSmallFont) {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 16px;
            }
        }
    }
}
