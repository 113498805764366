#popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    animation-name: fadeIn;
    animation-duration: 100ms;

    #popupBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.5);
    }

    #popupContent {
        max-width: $maxWidth;
        width: 100%;
    }
}

#popupContactUs {
    max-width: 80%;
    margin: auto;
    position: relative;
    background-color: $neutralMainColor1;
    border-radius: 20px;

    @media (max-width: $screenWidthSmallFont) {
        max-width: 90%;
    }

    form {
        @media (min-width: ($screenWidthSmallFont + 1)) {
            max-width: 76%;
        }
    }
}

#popupSubscribe {
    max-width: 80%;
    margin: auto;
    position: relative;
    background-color: $neutralMainColor1;
    border-radius: 20px;

    @media (max-width: $screenWidthSmallFont) {
        max-width: 90%;
    }

    form {
        @media (min-width: ($screenWidthSmallFont + 1)) {
            max-width: 76%;
        }
    }
}