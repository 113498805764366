#newReleaseMedia {
    gap: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid $neutralMainColor2;

    @media (max-width: $screenWidthSmallFont) {
        gap: 2px;
        margin-bottom: 23px;
    }

    .headerContainer {
        width: fit-content;

        @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 1280px) {
            padding: 8px 28px;

            h2 {
                font-size: 27px;
                line-height: 33px;
            }

            .ornament {
                .left,
                .right {
                    width: 56px;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 28px;
                    }
                }
            }

            .lineContainer {
                .hLine1 {
                    top: 14px;
                }
                .hLine2 {
                    bottom: 14px;
                }
            }
        }
    }

    .imageContainer {
        background-color: $neutralMainColor3;

        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.5);
        border-radius: 40px;

        width: 100%;
        aspect-ratio: 1080/608;

        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        overflow: hidden;

        @media (max-width: $screenWidthSmallFont) {
            margin-top: 6px;

            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
            border-radius: 20px;
        }
    }

    .title {
        padding-top: 13px;
        @include fontSubheading();
        font-weight: 600;
        color: $neutralMainColor4;
        text-transform: uppercase;
    }

    .speaker {
        @include fontSpeaker();
    }
}
