#ministryCards {
    position: relative;
    display: flex;
    gap: 184px;
    flex-direction: column;
    width: 100%;

    @media (max-width: $screenWidthSmallFont) {
        gap: 47px;
    }

    .ministryCard {
        position: relative;
        width: 100%;

        .heading {
            position: relative;
            display: flex;
            height: 50px;

            @media (max-width: $screenWidthSmallFont) {
                height: 24px;
            }

            .hLine1,
            .hLine2 {
                position: absolute;
                width: calc(100% - 50px);
                right: 0;
                height: 1px;
                background-color: $neutralMainColor2;
            }

            .hLine2 {
                top: 33px;

                @media (max-width: $screenWidthSmallFont) {
                    top: 17px;
                }
            }

            .ornament {
                flex: 1;
                display: flex;
                min-width: 100px;
                max-width: 340px;
                width: 100%;
                height: 100%;
                z-index: 1;

                @media (max-width: $screenWidthSmallFont) {
                    max-width: 80px;
                    min-width: 60px;
                }

                .left,
                .right,
                .center {
                    background-color: $neutralMainColor1;
                }

                .left,
                .right {
                    width: 50px;
                    border-block: 1px solid $neutralMainColor2;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 25px;
                    }
                }

                .left {
                    border-left: 1px solid $neutralMainColor2;
                    border-top-left-radius: 100%;
                }

                .right {
                    border-right: 1px solid $neutralMainColor2;
                    border-bottom-right-radius: 100%;
                }

                .center {
                    flex: 1;
                    border-block: 1px solid $neutralMainColor2;
                }
            }

            h1 {
                @include fontTitle();
                flex: 3;
                text-align: right;
                line-height: 34px;
                // border-top: 1px solid $neutralMainColor2;

                @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 1024px) {
                    font-size: 32px;
                }

                @media (max-width: $screenWidthSmallFont) {
                    line-height: 18px;
                    margin-top: -3px;
                }

                .title {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    @media (max-width: 1280px) {
                        font-size: 3vw;
                    }

                    @media (max-width: $screenWidthSmallFont) {
                        margin-right: -7px;
                    }

                    @media (max-width: 580px) {
                        font-size: 4vw;
                    }
                }

                svg {
                    margin-left: 17px;

                    .iconColor {
                        fill: $neutralMainColor5;
                    }

                    @media (max-width: $screenWidthSmallFont) {
                        margin-left: 0px;
                        transform: scale(0.5);
                    }
                }
            }
        }

        .body {
            position: relative;
            width: 100%;

            .bodyContainer {
                position: relative;
                width: 100%;
                border-bottom-right-radius: 9%;
                overflow: hidden;

                @media (min-width: 1281px) {
                    min-height: 642px;
                }

                @media (max-width: 1280px) {
                    border-bottom-right-radius: 0;
                }

                .spacer {
                    height: 6px;
                }

                .spacer96 {
                    height: 96px;
                }

                .margin64 {
                    margin-top: -64px;
                }

                .leftBorder {
                    border-left: 1px solid $neutralMainColor2;
                }

                .leftBorderMirror {
                    border-left: 1px solid $neutralMainColor2;
                }

                .leftContainer {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    max-width: 620px;

                    @media (max-width: 1280px) {
                        width: 100%;
                        max-width: 100%;
                    }

                    .cardContent {
                        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.3);
                        border-radius: 0px 0px 60px 60px;
                        background-color: $neutralMainColor1;
                        color: $neutralMainColor5;

                        @media (max-width: $screenWidthSmallFont) {
                            box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
                            border-radius: 0px 0px 30px 30px;
                        }

                        .cardContentData {
                            padding: 74px 160px 113px 50px;

                            @media (min-width: 1281px) {
                                min-width: 620px;
                            }

                            @media (max-width: 1280px) {
                                padding: 74px 50px 40px 50px;
                                margin-top: -210px;
                            }

                            @media (max-width: $screenWidthSmallFont) {
                                padding: 20px 20px 20px 20px;
                                margin-top: 0;
                            }
                        }

                        .cardContentImageMobile {
                            display: none;

                            @media (max-width: 1280px) {
                                display: block;
                                mask-image: url("./../../../img/card/ministry/maskingMobile.svg");
                                -webkit-mask-repeat: no-repeat;
                                mask-repeat: no-repeat !important;
                                mask-size: contain;
                                mask-position: top right;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: cover;
                                width: 100%;
                                aspect-ratio: 310/240;
                            }

                            @media (max-width: $screenWidthSmallFont) {
                                mask-image: none;
                            }
                        }

                        .title {
                            min-height: 150px;
                            display: flex;
                            align-items: flex-end;

                            @include fontScriptType();

                            @media (max-width: 1280px) {
                                width: 70%;
                            }

                            @media (max-width: $screenWidthSmallFont) {
                                width: 100%;
                                min-height: 0;
                            }

                            @media (min-width: ($screenWidthSmallFont + 1)) and (max-width: 1024px) {
                                font-size: 29px;
                            }
                        }

                        .tagline {
                            @include fontTagline();
                        }

                        a.link {
                            @media (max-width: $screenWidthSmallFont) {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .data {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;

                        @media (max-width: 1024px){
                            flex-direction: column;
                            align-items: center;
                            gap: 24px;
                        }
                    }

                    .info {
                        display: flex;
                        text-align: left;

                        .left,
                        .right {
                            background-color: $neutralMainColor5;
                            width: 44px;
                        }

                        .left {
                            border-bottom-left-radius: 100%;

                            @media (max-width: 1024px) {
                                border-bottom-left-radius: 24px;
                            }
                        }

                        .right {
                            border-top-right-radius: 100%;

                            @media (max-width: 1024px) {
                                border-top-right-radius: 24px;
                            }
                        }

                        .center {
                            background-color: $neutralMainColor5;
                            color: $neutralMainColor1;

                            p {
                                margin-bottom: 0;
                            }

                            @media (max-width: 1024px){
                                width: 100%;
                                
                            }
                        }

                        @media (max-width: 1024px){
                            width: 100%;
                        }
                        
                    }
                }

                .rightContainerShadow {
                    pointer-events: none;
                    position: absolute;
                    width: 670px;
                    height: 100%;
                    top: 0;
                    left: calc(1040px - 670px);
                    background-image: url("./../../../img/card/ministry/masking.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    filter: drop-shadow(-5px 2px 5px rgba(0, 0, 14, 0.4));

                    @media (max-width: 1280px) {
                        display: none;
                    }
                }

                .rightContainer {
                    pointer-events: none;
                    position: absolute;
                    width: 670px;
                    height: 100%;
                    top: 0;
                    left: calc(1040px - 670px);
                    mask-image: url("./../../../img/card/ministry/masking.svg");
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat !important;
                    mask-size: contain;
                    mask-position: top right;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    @media (max-width: 1280px) {
                        display: none;
                    }
                }

                .specialButton {
                    width: 140px;
                    right: auto;
                    left: 640px;
                    bottom: 67px;
                    height: 196px;

                    filter: drop-shadow(-5px 2px 5px rgba(0, 0, 0, 0.4));
                    // filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25));

                    .centerContainer {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin: auto;
                        gap: 34px;
                        flex-direction: column;
                        margin-top: -34px - 40px;
                    }

                    .normalButton {
                        width: fit-content;
                        margin: auto;
                    }

                    @media (max-width: 1280px) {
                        display: none;
                    }
                }
            }
        }

        &.even {
            .body {
                .bodyContainer {

                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 9%;

                    @media (max-width: 1280px) {
                        border-bottom-left-radius: 0;
                    }

                    .leftBorderMirror {
                        border-left: none;
                        border-right: 1px solid #BDBDBD;
                    }

                    .leftContainer {
                        margin-left: auto;
                        margin-right: 0;

                        .cardContent {
                            .cardContentData {
                                @media (min-width: 1281px) {
                                    padding: 74px 50px 113px 160px;
                                }
                            }

                            .cardContentImageMobile {
                                @media (max-width: 1280px) {
                                    mask-image: url("./../../../img/card/ministry/maskingEvenMobile.svg");
                                    mask-position: top left;
                                }

                                @media (max-width: $screenWidthSmallFont) {
                                    mask-image: none;
                                }
                            }

                            .title {
                                @media (max-width: 1280px) {
                                    margin-left: auto;
                                    margin-right: 0;
                                    text-align: right;
                                    justify-content: flex-end;
                                }

                                @media (max-width: $screenWidthSmallFont) {
                                    margin-right: auto;
                                    margin-left: 0;
                                    text-align: left;
                                    justify-content: flex-start;
                                }
                            }
                        }

                        .data {
                            flex-direction: row-reverse;

                            @media (max-width: 1024px){
                                flex-direction: column;
                                align-items: center;
                                gap: 24px;
                            }

                            .info {
                                .left {
                                    border-bottom-left-radius: 0;
                                    border-top-left-radius: 100%;

                                    @media (max-width: 1024px) {
                                        border-top-left-radius: 24px;
                                    }
                                }

                                .right {
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 100%;

                                    @media (max-width: 1024px) {
                                        border-bottom-right-radius: 24px;
                                    }
                                }
                            }
                        }
                    }

                    .rightContainerShadow {
                        left: auto;
                        right: 373px;

                        background-image: url("./../../../img/card/ministry/maskingEven.svg");
                        filter: drop-shadow(5px 2px 5px rgba(0, 0, 14, 0.4));
                    }

                    .rightContainer {
                        left: auto;
                        right: 370px;

                        mask-image: url("./../../../img/card/ministry/maskingEven.svg");
                        mask-position: -3px 0px;
                    }

                    .specialButton {
                        left: auto;
                        right: 640px;
                    }
                }
            }

        }

        .anchorPoint {
            top: -207px;
        }
    }

    .specialButtonMobile {
        @media (min-width: 1281px) {
            display: none;
        }

        @media (max-width: $screenWidthSmallFont) {
            padding-right: 0;
        }

        .centerContainer {
            display: flex;
            justify-content: center;
            gap: 11px;
            flex-direction: row;
        }

        &.even {
            .centerContainer {
                flex-direction: row-reverse;
            }
        }
    }

    .notify {
        background-color: $neutralMainColor5;
        color: $neutralMainColor1;
        border: 0.5px solid $neutralMainColor1;
    }

    .join {
        border: 0.5px solid $neutralMainColor2;
        
        &:not(:disabled) {
            background-color: $okColor;
            color: $neutralMainColor1;
        }
    
        &:disabled {
            background-color: $neutralMainColor1;
            color: $neutralMainColor5;
            filter: brightness(0.8);
        }
    }
}

body.dark #ministryCards {
    .ministryCard {
        .heading .ornament {

            .left,
            .right,
            .center {
                background-color: $neutralMainColor5;
            }
        }

        .heading h1 svg .iconColor {
            fill: $neutralMainColor1;
        }

        .body .bodyContainer .leftContainer .info {

            .left,
            .right,
            .center {
                background-color: $neutralMainColor1;
                color: $neutralMainColor5;
            }
        }

        .body .bodyContainer .specialButton {
            img {
                filter: invert(1);
            }

            .specialButtonContainer .line {
                stroke: $neutralMainColor1;
            }
        }
    }
}

.ministryCard{
    @media (max-width: 1280px) {
        .cardContent {
            border: 1px solid $neutralMainColor2;
        }
    }

    @media (min-width: 1281px) {
        &.odd {
            .cardContentData{
                border-left: 1px solid $neutralMainColor2;
                border-radius: 0px 0px 60px 60px;
            }
        }
    
        &.even {
            .cardContentData{
                border-right: 1px solid $neutralMainColor2;
                border-radius: 0px 0px 60px 60px;
            }
        }
    }    
}