$trinityCardWidthMobile: 136px;
$trinityCardWidth: 342px;

#trinityCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-left: -$stickyLeftPadding;
    position: relative;
    gap: 41px;

    @media (max-width: $screenWidthSmallFont) {
        margin-left: -20px;
        margin-right: 24px;
    }

    .tCard {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: $neutralMainColor1;
        width: $trinityCardWidth;
        height: 508px;

        box-shadow: 0px 10px 16px 6px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 60px 60px;
        color: $neutralMainColor5;
        padding-bottom: 48px;

        @media (max-width: $screenWidthSmallFont) {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            padding-bottom: 0;
            height: 160px;
            min-height: auto;

            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 0px 0px 40px 40px;
        }

        & > div:not(:first-child){
            padding-inline: 31px;

            @media (max-width: $screenWidthSmallFont) {
                padding-inline: 16px;
            }
        }

        .image {
            width: $trinityCardWidth;
            background-color: $neutralMainColor3;
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat !important;

            @media (max-width: $screenWidthSmallFont) {
                width: $trinityCardWidthMobile;
                mask-size: $trinityCardWidthMobile auto;
            }
        }

        .titleContainer {
            position: relative;
            min-width: $trinityCardWidth;
            width: $trinityCardWidth;
            padding-top: 247px;

            .title {
                font-family: 'Baskerville Normal', serif;
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 32px;
                text-transform: uppercase;
            }

            @media (max-width: $screenWidthSmallFont) {
                min-width: $trinityCardWidthMobile;
                width: $trinityCardWidthMobile;
                padding-top: 106px;

                .title {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        
        .content {
            @media (max-width: $screenWidthSmallFont) {
                padding-top: 10px;
            }
        }
    }

    .tCard{
        &:nth-of-type(1) {
            .image {
                height: 287px;
                mask-image: url('./../../../img/card/trinity/masking1.svg');
                mask-position: 0 -1px;

                @media (max-width: $screenWidthSmallFont) {
                    height: 114px;
                }
            }

            .titleContainer {
                .icon {
                    position: absolute;
                    background: url('./../../../img/card/trinity/father.svg') no-repeat;
                    top: 119px;
                    right: 28px;
                    width: 177px;
                    height: 152px;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 63px;
                        height: 53px;
                        background-size: contain;
                        top: 66px;
                        right: 11px;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            .image {
                height: 221px;
                mask-image: url('./../../../img/card/trinity/masking2.svg');

                @media (max-width: $screenWidthSmallFont) {
                    height: 88px;
                }
            }

            .titleContainer {
                .icon {
                    position: absolute;
                    background: url('./../../../img/card/trinity/son.svg') no-repeat;
                    top: 158px;
                    left: 31px;
                    width: 74px;
                    height: 113px;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 30px;
                        height: 47px;
                        background-size: contain;
                        top: 71px;
                        left: 18px;
                    }
                }
            }
        }

        &:nth-of-type(3) {
            border-top-right-radius: 105px;

            @media (max-width: $screenWidthSmallFont) {
                border-top-right-radius: 40px;
            }
            
            .image {
                height: 348px;

                mask-image: url('./../../../img/card/trinity/masking3.svg');

                @media (max-width: $screenWidthSmallFont) {
                    height: 136px;
                }
            }

            .titleContainer {
                .icon {
                    position: absolute;
                    background: url('./../../../img/card/trinity/holySpirit.svg') no-repeat;
                    top: 111px;
                    left: 48px;
                    width: 194px;
                    height: 161px;

                    @media (max-width: $screenWidthSmallFont) {
                        width: 12px;
                        height: 61px;
                        background-size: contain;
                        top: 61px;
                        left: 27px;
                    }
                }
            }
        }
    }
}