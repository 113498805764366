#grow {
}

.growCards {
    gap: 175px;

    @media (max-width: $screenWidthSmallFont) {
        gap: 40px;
    }

    .growCard {
        .leftBorder {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-left: 1px solid $neutralMainColor2;
        }
        
        .image {
            width: 100%;
            background-color: $neutralMainColor3;
            background-size: cover;
            background-position: center center;
            mask-size: contain;
            mask-position: top right;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat !important;
        }

        .content {
            padding-inline: 40px var(--default-padding-right);
            max-width: calc(276px + 40px + var(--default-padding-right));

            @media (max-width: $screenWidthSmallFont) {
                padding-inline: 17px;
            }

            .title {
                @include fontLibreHeading();
                margin-bottom: 30px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-bottom: 7px;
                }
            }
        }

        &.grow101 {
            .leftBorder {
                mask-border-source: linear-gradient(
                    to bottom,
                    transparent 0%,
                    transparent 10%,
                    black calc(100% - 41px),
                    transparent calc(100% - 40px),
                    transparent 100%
                );
            }

            .image {
                aspect-ratio: 1080/544;
            }

            .content {
                margin-top: -250px;
                padding-block: 0 50px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-top: -132px;
                    padding-inline: 17px;
                    padding-block: 104px 20px;
                }
            }
        }

        &.grow102 {
            .leftBorder {
                mask-border-source: linear-gradient(
                    to bottom,
                    transparent 0%,
                    transparent 10%,
                    black calc(100% - 41px),
                    transparent calc(100% - 40px),
                    transparent 100%
                );
            }

            .image {
                aspect-ratio: 1080/496;
            }

            .content {
                margin-top: -321px;
                padding-block: 120px 50px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-top: -207px;
                    padding-inline: 17px;
                    padding-block: 183px 20px;
                }

                @media (max-width: 500px) {
                    margin-top: -132px;
                    padding-top: 126px;
                }
            }
        }

        &.grow103 {
            .leftBorder {
                mask-border-source: linear-gradient(
                    to bottom,
                    black 0%,
                    black calc(100% - 41px),
                    transparent calc(100% - 40px),
                    transparent 100%
                );
            }

            .image {
                aspect-ratio: 1080/592;
            }
            .content {
                margin-top: -551px;
                padding-block: 352px 50px;

                @media (max-width: $screenWidthSmallFont) {
                    margin-top: -207px;
                    padding-inline: 17px;
                    padding-block: 183px 20px;
                }

                @media (max-width: 500px) {
                    margin-top: -132px;
                    padding-top: 126px;
                }
            }
        }

        .paddingLeft {
            padding-left: 40px;
            padding-bottom: 60px;
            border-left: 1px solid $neutralMainColor2;
        }

        .light.leftAligned {
            margin-left: 0;

            .info {
                margin-top: 0;
            }
        }

        .light .info {
            .left,
            .right,
            .center {
                border: 1px solid $neutralMainColor2;
            }
            .left {
                border-right: none;
            }
            .right {
                border-left: none;
            }
            .center {
                border-inline: none;
            }
        }
    }
}
